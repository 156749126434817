import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import MovingText from "react-moving-text";
import founderimg from "../assets/Images/founderashirwad.png";

import style from "../NavbarComponents/styles/aboutfounder.module.css";
import Footer from "../Components2/Footer";
import NavTopnav from "../components/NavTopnav/NavTopnav";

const AboutFounder = () => {
  const styles = {
    background: {
      // backgroundImage: "url(/course2.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      opacity: "1",
      height: "100%",
      position: "absolute",
      top: 0,
    },
  };

  return (
    <>
      <Box component="div" className={style.background}>
        <NavTopnav />
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid
            sx={{ marginTop: { md: "100px", sm: "50px", xs: "50px" } }}
            item
            xs={12}
            md={10}
          >
            <img
              src={founderimg}
              alt=""
              style={{
                height: "400px",
                width: "400px",
                objectFit: "contain",

                borderRadius: "5px",
              }}
            />
            {/* <MovingText
              type="slideInFromLeft"
              duration="2000ms"
              delay="0.3s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            > */}
            <Typography variant="h4" sx={{ color: "orangered", mt: 1 }}>
              Dr.H. Veerabhadra Gouda Hosamani
            </Typography>
            {/* </MovingText> */}
            <Box sx={{ margin: "5%" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Dr.H. Veerabhadra Gouda Hosamani, established Ashirwad Pu
                College with a singular vision to create a nurturing environment
                where students can excel academically and grow into responsible,
                compassionate, and innovative individuals. With a rich
                background in education and a deep commitment to fostering
                holistic development, Dr.H. Veerabhadra Gouda Hosamani, has
                dedicated their life to shaping the future of young minds.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Under Dr.H. Veerabhadra Gouda Hosamani's leadership, Ashirwad Pu
                College has achieved numerous milestones. The school is
                recognized for its innovative teaching methods, outstanding
                faculty, and exceptional student performance in both academics
                and extracurricular activities. Dr.H. Veerabhadra Gouda
                Hosamani, has also been instrumental in developing programs that
                support students' mental and emotional well-being, ensuring a
                balanced approach to education.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Dr.H. Veerabhadra Gouda Hosamani, believes that education is the
                most powerful tool to change the world. They are a firm advocate
                for lifelong learning and encourage both students and staff to
                continually seek knowledge and personal growth. Their dedication
                to education is not just professional but deeply personal,
                driven by the belief that every child deserves the opportunity
                to reach their full potential.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </>
  );
};

export default AboutFounder;
