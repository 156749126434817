import React, { useContext } from "react";
import { Grid, createTheme } from "@material-ui/core";

// // import CardContents from "../components/Cards/CardContents";
import Footer from "../components/Footer/Footer";
// import WhyRegister from "../components/WhyRegister/Whyregister";
import AboutSchool from "../components/AboutSchool/AboutSchool";
import Infrastructure from "../components/Infrastructure/Infrastructure";
import JoinSession from "../components/JoinSession/JoinSession";
import Slider from "../components/slider/Slider";
import Birthday from "../components/BirthdayEvents/Birthday";
// import PrincipleDesk from "../components/PrincipleDesk/PrincipleDesk";
// import Socialmedia from "../components/Socialmedia/Socialmedia";
import SchoolCard from "../components/SchoolCard/SchoolCard";
import Chairman from "../components/Chairman/Chairman";
import Qualifiedteacher from "../components/Chairman/Qualifiedteacher";
import NewsNotic from "../components/NewsNotic/NewsNotic";
import Latestevents from "../components/Latestevents/Latestevents";
import "../styles/components/ScrollTop/ScrollTop.scss";
import { downArrow } from "@syncfusion/ej2-react-grids";
import CourseMain from "../components/HomeComponents/CourseMain";
import Video from "../components/HomeComponents/Video";
import TeachersCard from "../components/HomeComponents/TeachersCard";
import GalleryMain from "../components/HomeComponents/GalleryMain";
import CardMain from "../components/HomeComponents/CardMain";
import ScrollUp from "../components/ScrollUp";
import Information from "../components/HomeComponents/Information";
import SettingContext from "../context/SettingsContext";

// Second theme components

import MidNav from "../NavbarComponents/MidNav";
import LoginForm from "../NavbarComponents/LoginForm";
import HomeAboutUs from "../Components2/HomeAboutUs";
import BlogMain from "../Components2/Category/BlogMain";
import OurGallery from "../Components2/HomePage/OurGallery";
import NewsMain from "../Components2/NewsAndNotice/NewsMain";
import Events from "../Components2/events/Events";
import CreateEvents from "../Components2/CreateEvents";
import OurAchievements from "../ourblog/OurAchievements";
import Footer2 from "../Components2/Footer";
import { ThemeProvider } from "@emotion/react";
import Results from "../Components2/Results";
import Feedbacks from "../components/Feedbacks/Feedbacks";
import { Box } from "@mui/material";
import NewsCardMain from "../components/HomeComponents/NewsCardMain";

const Home = () => {
  const { selectedSetting } = useContext(SettingContext);

  const theme = createTheme();

  if (selectedSetting.themeOrder % 2 !== 0) {
    return (
      <div>
        <ScrollUp />
        <Slider selectedSetting={selectedSetting} />
        <CardMain />
        <Birthday />
        <Feedbacks />
        <SchoolCard />
        <Video />
        <NewsCardMain />
        <CourseMain />
        <Information />
        <GalleryMain />
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <ScrollUp />
        <MidNav selectedSetting={selectedSetting} />
        <LoginForm />
        <ThemeProvider theme={theme}>
          <HomeAboutUs />
        </ThemeProvider>

        <BlogMain />
        <OurGallery />
        <NewsMain />
        <Events />
        <CreateEvents />
        <OurAchievements />
        <Footer2 />

        {/* <Results/> */}
      </div>
    );
  }
};
export default Home;
