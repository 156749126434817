import { Box, Container, Typography, styled } from "@mui/material";
import React from "react";
// import image from '../../assets/Images/bg-image.png'
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../navbar/Navbar";
import TopNav from "../navbar/TopNav";

const ContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
    // overflow:"auto",
  },
}));

const OverViewTitleContainer = styled(Box)(({ theme }) => ({
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const OverViewTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const OverView = () => {
  return (
    <>
      <TopNav />
      <NavBar />

      <ContentBox sx={{ height: "max-content" }}>
        <OverViewTitleContainer variant="h4" align="center">
          <OverViewTitle>OVERVIEW</OverViewTitle>
          <Typography sx={{ color: "white" }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              HOME
            </Link>
            &nbsp; / ABOUT
          </Typography>
        </OverViewTitleContainer>
        <Container>
          <Box sx={{ textAlign: "justify" }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                color: "#F86F03",
                textIndent: "4rem",
                marginTop: "2rem",
              }}
            >
              At Ashirwad Global School, we take pride in our rich history and
              the visionary leader who laid the foundation for our institution.
              Our school founder, Dr.H. Veerabhadra Gouda Hosamani, was a
              remarkable individual who believed in the transformative power of
              education and dedicated their life to shaping young minds.
            </Typography>

            <Typography sx={{ marginTop: "1rem" }}>
              <b> Ashirwad Global School</b> is the pioneering institution of
              the Hunsagi district. The school has been conceptualized and
              established to provide equal educational opportunities for the
              children geographically removed from the centers of economic
              development.
            </Typography>

            <Typography sx={{ marginTop: "1rem" }}>
              The school follows the CBSE curriculum and advanced pedagogical
              practices have been put in place to get the desired learning
              outcomes. The co-curriculum has been designed to provide maximum
              exposure to the children. Emphasis has been placed on engendering
              digital literacy and technological proficiency in children.
            </Typography>

            <Typography sx={{ marginTop: "1rem" }}>
              The faculty has been recruited from across India on the basis of
              merit and experience and is well-versed in their subject matter as
              well as the elements of child development.
            </Typography>

            <Typography sx={{ marginTop: "1rem" }}>
              The school has an expansive 10 acre campus equipped with all the
              amenities for world-class schooling which includes modern learning
              spaces, sports and activity zones and boarding facilities.
            </Typography>

            <Typography
              sx={{
                marginTop: "3rem",
                fontWeight: "bold",
                color: "#F86F03",
                textAlign: "center",
              }}
            >
              Come and be a part of the legacy at Ashirwad Global School!
            </Typography>
          </Box>
        </Container>
      </ContentBox>
      <Footer />
    </>
  );
};

export default OverView;
