import React, { useContext } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";

import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { GrGooglePlus } from "react-icons/gr";
import { BsYoutube } from "react-icons/bs";
import { BiLogoInstagram } from "react-icons/bi";

import logo from "../../assets/Images/eCampusstreet.png";

import style from "./footer.module.css";
import { styled, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SettingContext from "../../context/SettingsContext";
// icons
import { Facebook, Twitter } from "@mui/icons-material";

const FooterNav = styled(Box)(({ theme }) => ({
  color: "white",
  fontFamily: "sans-serif",
  fontSize: "1rem",
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: "white",
  padding: "20px 0",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  transition: "1s",
}));

const FooterSectionTitle = styled(Typography)(() => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: "#F86F03",
  margin: "0px 0px 8px",
  fontFamily: "sans-serif",
  display: "inline",
}));

const Footer = () => {
  const { selectedSetting } = useContext(SettingContext);
  // console.log(selectedSetting, "::::");

  return (
    <Box style={{ backgroundColor: "black", marginTop: "3rem" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} md={6} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={selectedSetting?.logo?.link || logo}
                alt="logo"
                height={180}
                width={180}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            md={6}
            sm={6}
            style={{
              marginBottom: "2rem",
              marginTop: "2rem",
              display: "flex",
              // justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FooterSectionTitle>USEFULL LINKS</FooterSectionTitle>

            <Box
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
              }}
            >
              <Box className={style.usefull}>
                <Link to="/"> Home </Link>
                <Link to="/about/overview"> About </Link>
                <Link to="/home-gallery"> Gallery </Link>
              </Box>

              <Box className={style.usefull}>
                <Link to="/results"> Result </Link>
                <Link to="/facilities/library"> Facilities </Link>
                <Link to="/contact-us"> Contact us </Link>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={2}
            md={6}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
          >
            <Box>
              <FooterSectionTitle>FOLLOW US</FooterSectionTitle>
            </Box>

            <Box className={style.logobox}>
              {selectedSetting.facebookUrl ? (
                <span>
                  <Link to={selectedSetting.facebookUrl} target="_blank">
                    {" "}
                    <Facebook className={style.logo} size={20} />{" "}
                  </Link>
                </span>
              ) : null}

              {selectedSetting?.twitterUrl ? (
                <span>
                  <Link to={selectedSetting.twitterUrl} target="_blank">
                    {" "}
                    <Twitter className={style.logo} size={20} />
                  </Link>
                </span>
              ) : null}

              {selectedSetting?.instagramUrl ? (
                <span>
                  <Link to={selectedSetting.instagramUrl} target="_blank">
                    {" "}
                    <BiLogoInstagram className={style.logo} size={25} />{" "}
                  </Link>
                </span>
              ) : null}

              {selectedSetting?.gplusUrl ? (
                <span>
                  <Link to={selectedSetting.gplusUrl} target="_blank">
                    {" "}
                    <GrGooglePlus className={style.logo} size={25} />{" "}
                  </Link>
                </span>
              ) : null}

              {selectedSetting?.youtubeUrl ? (
                <span>
                  <Link to={selectedSetting.youtubeUrl} target="_blank">
                    {" "}
                    <BsYoutube className={style.logo} size={20} />{" "}
                  </Link>
                </span>
              ) : null}

              {selectedSetting?.linkedinUrl ? (
                <span>
                  <Link to={selectedSetting.linkedinUrl} target="_blank">
                    {" "}
                    <BiLogoLinkedin className={style.logo} size={25} />{" "}
                  </Link>
                </span>
              ) : null}
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} md={6} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginLeft: "1rem",
                marginTop: "2rem",
              }}
            >
              <FooterSectionTitle>ADDRESS</FooterSectionTitle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  rowGap: "5px",
                }}
              >
                <FooterNav>Shorapur, Main Rd, Hunasagi</FooterNav>
                <FooterNav> Karnataka 585215</FooterNav>
                {/* <FooterNav>
                  A Block, Milk Colony, 2nd Stage, Rajajinagar
                </FooterNav>
                <FooterNav>Bengaluru, Karnataka, Pin : 560010</FooterNav>  */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        sx={{
          marginTop: { lg: "2rem", xs: "3rem" },
          background: "rgba(255,255,255,0.1)",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FooterText>{selectedSetting.websiteFooter}</FooterText>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FooterText>
            <Link to="https://ecampusstreet.com/" target="_blank">
              <span className={style.ecampusheading}>
                Powered by <span className={style.ecampus}>eCampusStreet</span>
              </span>
            </Link>
          </FooterText>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
