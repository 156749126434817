import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";

import { Link } from "react-router-dom";
import Footer from "../../Components2/Footer";
import NavTopnav from "../../components/NavTopnav/NavTopnav";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://t3.ftcdn.net/jpg/04/79/55/50/240_F_479555081_2LfBMnoLS7XVFjrGD26i5mzXqtXVyN0X.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "150px",
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
  },
}));

const Overview = () => {
  return (
    <Box>
      <NavTopnav />
      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700"
        >
          OVERVIEW
        </Typography>
        <ContentContainer>
          <Box sx={{ textAlign: "justify" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, color: "white" }}
            >
              At Ashirwad Pu College, we take pride in our rich history and the
              visionary leader who laid the foundation for our institution. Our
              college founder, Dr.H. Veerabhadra Gouda Hosamani, was a
              remarkable individual who believed in the transformative power of
              education and dedicated their life to shaping young minds.
            </Typography>
            <br />

            <Typography sx={{ color: "white" }}>
              <b> Ashirwad Pu College</b> is the pioneering institution of the
              Hunsagi district. The school has been conceptualized and
              established to provide equal educational opportunities for the
              children geographically removed from the centers of economic
              development
            </Typography>
            <br />

            <Typography sx={{ color: "white" }}>
              The school follows the CBSE curriculum and advanced pedagogical
              practices have been put in place to get the desired learning
              outcomes. The co-curriculum has been designed to provide maximum
              exposure to the children. Emphasis has been placed on engendering
              digital literacy and technological proficiency in children.
            </Typography>
            <br />

            <Typography sx={{ color: "white" }}>
              The faculty has been recruited from across India on the basis of
              merit and experience and is well-versed in their subject matter as
              well as the elements of child development.
            </Typography>
            <br />

            <Typography sx={{ color: "white" }}>
              The school has an expansive 10 acre campus equipped with all the
              amenities for world-class schooling which includes modern learning
              spaces, sports and activity zones and boarding facilities.
            </Typography>
            <br />

            <Typography sx={{ color: "white" }}>
              <b> Come and be a part of the legacy at Ashirwad Pu College!</b>
            </Typography>
          </Box>
        </ContentContainer>

        <Footer />
      </OuterBox>
    </Box>
  );
};

export default Overview;
