import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Typography,
  Grid,
  useMediaQuery,
  TablePagination,
  Tooltip,
} from "@mui/material";
// icons
import { Add } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// custome components
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import DeleteModal from "../Academic/DeleteModal";
import SectionSubHeader from "../../SectionSubHeader";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";
import { styled } from "@mui/styles";
import moment from "moment";

const MuiGrid = styled(Grid)(({ theme }) => ({
  alignSelf: "center",
  display: "flex",
  justifyContent: "flex-end",
  gap: "10px",
}));

const MuiBoxCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
}));

const MuiTableTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const MuiText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
}));

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (["class"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class`;
      result.push(newItem);
    } else if (["classOld"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-Old`;
      result.push(newItem);
    } else if (["classNew"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-New`;
      result.push(newItem);
    } else if (dep === "hostel") {
      let newItem = `[${data.hostel?.name}]-Hostel`;
      result.push(newItem);
    } else if (dep == "transport") {
      let newItem = `[${data.route.transportRouteTitle}]--[${data.stop.stopName}]--[${data.pickType}]`;
      result.push(newItem);
    } else if (dep == "pickType") {
      let newItem = `[${data.pickType}]-Pick_Type`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

function FeeMapCategoryList() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [deleteModal, setDeleteModal] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [feeMaps, setFeeMaps] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [feeCategory, setFeeCategory] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [editFeeCategory, setEditFeeCategory] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [receiptTitles, setReceiptTitles] = useState([]);
  const [selectedReceiptTitle, setSelectedReceiptTitle] = useState("");
  const [search, setSearch] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [hostels, setHostels] = useState([]);
  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ===========================

  useEffect(() => {
    const getReceiptNames = async () => {
      try {
        const { data: allReceipts, status } = await get(
          `${urls.receiptTitle.list}`,
          {
            headers: { roleFunction: "feeMapCategory" },
          }
        );
        if (status > 199 && status < 299) {
          setReceiptTitles(allReceipts.data);
          if (allReceipts.data.length > 0) {
            setSelectedReceiptTitle(allReceipts.data[0]._id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    getReceiptNames();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getFeeMaps = async () => {
      try {
        const { data: feeMapData, status } = await get(
          `${urls.feeMap.list}/${selectedReceiptTitle}`,
          {
            headers: { roleFunction: "feeMapCategory" },
            params: { search: { active: true } },
          }
        );

        if (status > 199 && status < 299) {
          setFeeMaps(feeMapData.data);
          setAddForm((prev) => ({
            ...prev,
            feeMap: feeMapData.data.length ? feeMapData.data[0]._id : "",
          }));
        }
      } catch (error) {
        return error;
      }
    };
    if (selectedReceiptTitle) {
      getFeeMaps();
    }
  }, [selectedReceiptTitle, selectedSetting._id]);

  useEffect(() => {
    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`,
          { headers: { roleFunction: "feeCategory" } }
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
          if (schoolClassesList.data.length) {
            setAddForm((prev) => ({
              ...prev,
              classId: schoolClassesList.data[0]._id,
            }));
          }
        }
      } catch (error) {}
    };

    getSchoolClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    if (addForm.feeMap) {
      getFeeCategory();
    }
  }, [addForm.feeMap]);

  const getFeeCategory = async () => {
    try {
      const { data: feeCategoryList, status } = await get(
        `${urls.feeMapCategory.list}`,
        {
          headers: { roleFunction: "feeMapCategory" },
          params: {
            search: {
              feeMap: addForm.feeMap,
            },
          },
        }
      );

      const compare = (a, b) => a.priority - b.priority;
      let feeCategoryss = feeCategoryList.data;
      feeCategoryss.sort(compare);

      if (status > 199 && status < 299) {
        setFeeCategory(feeCategoryss);
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.feeMapCategory.delete}`,
        id,
        {},
        { roleFunction: "feeMapCategory" }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvent = feeCategory.filter((item) => item._id !== id);
        setFeeCategory(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteMultiple = async () => {
    try {
      const res = await del(
        `${urls.feeCategory.deleteMultipleFeeCategory}`,
        "",
        { feeCategoryIds: selected },
        { roleFunction: "feeCategory" }
      );
      if (res.data.success) {
        const filteredEvents = feeCategory.filter(
          (item) => !selected.includes(item._id)
        );
        setFeeCategory(filteredEvents);
        setSelected([]);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const ids = feeCategory.map((fee) => fee._id);
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  const handlePriorityUp = async (index, id) => {
    if (!addForm.feeMap) return;
    try {
      const res = await put(
        `${urls.feeMapCategory.updatePriority}${id}`,
        "",
        {
          type: "up",
        },
        { headers: { roleFunction: "feeMapCategory" } }
      );
      // console.log("res", res);
      if (res.data.success) {
        getFeeCategory();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePriorityDown = async (index, id) => {
    if (!addForm.feeMap) return;

    try {
      const res = await put(
        `${urls.feeMapCategory.updatePriority}${id}`,
        "",
        {
          type: "down",
        },
        { headers: { roleFunction: "feeMapCategory" } }
      );

      if (res.data.success) {
        getFeeCategory();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseAddDialog = () => {
    setAddForm((prev) => ({ feeMap: prev.feeMap }));
    setEditFeeCategory("");
    setAddDialog(false);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (!editFeeCategory) {
        if (!addForm.feeMap) return alert("Please select a class map!");
        const { status } = await post(
          `${urls.feeMapCategory.create}`,
          {
            name: addForm.name,
            description: addForm.description,
            amount: Number(addForm.amount),
            feeMapId: addForm.feeMap,
          },
          { headers: { roleFunction: "feeMapCategory" } }
        );
        if (status > 199 && status < 299) {
          getFeeCategory();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.feeMapCategory.update}${editFeeCategory}`,
          "",
          {
            name: addForm.name,
            description: addForm.description,
            amount: Number(addForm.amount),
          },
          { headers: { roleFunction: "feeMapCategory" } }
        );
        if (status > 199 && status < 299) {
          getFeeCategory();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleFeeCategoryEdit = (id, data) => {
    setEditFeeCategory(id);
    setAddForm({
      feeMap: data.feeMap._id,
      name: data.name,
      description: data.description,
      amount: data.amount,
    });
    handleOpenAddDialog();
  };

  const getClasses = async () => {
    try {
      const { data } = await get(urls.class.getAllClass, {
        headers: { roleFunction: "feeMap" },
      });

      setClasses(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAcademicYears = async () => {
    try {
      const { data } = await get(urls.administrator.academicYearGetAll, {
        headers: { roleFunction: "feeMap" },
      });

      setAcademicYears(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutes = async () => {
    try {
      const { data } = await get(urls.route.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setRoutes(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getHostels = async () => {
    try {
      const { data } = await get(`${urls.hostel.getList}`, {
        headers: { roleFunction: "feeMap" },
      });
      setHostels(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRooms = async () => {
    try {
      const { data } = await get(urls.room.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setRooms(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRooms();
    getRoutes();
    getAcademicYears();
    getClasses();
    getHostels();
  }, []);

  return (
    <Wrapper>
      <SectionSubHeader title="Fee Map Category" />
      <MuiGrid container my={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl fullWidth size="small" required>
            <InputLabel>Receipt name</InputLabel>
            <Select
              size="small"
              name="receiptName"
              value={selectedReceiptTitle || ""}
              onChange={(e) => setSelectedReceiptTitle(e.target.value)}
              label="Receipt name"
            >
              {receiptTitles
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
                .map((receipt) => (
                  <MenuItem key={receipt._id} value={receipt._id}>
                    {receipt.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl fullWidth size="small" required>
            <InputLabel>Fee Maps</InputLabel>
            <Select
              size="small"
              name="feeMap"
              value={addForm.feeMap || ""}
              onChange={handleAddForm}
              label="Class Maps"
            >
              {feeMaps
                .sort((a, b) =>
                  a.receiptTitle.name
                    .toLowerCase()
                    .localeCompare(b.receiptTitle.name.toLowerCase())
                )
                .map((feeMap) => (
                  <MenuItem key={feeMap._id} value={feeMap._id}>
                    {showInfo(feeMap)} (₹
                    {feeMap.fee})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Button
          disabled={!addForm.feeMap}
          onClick={handleOpenAddDialog}
          size="small"
          variant="contained"
        >
          <Add />
          add fee category
        </Button>
      </MuiGrid>

      {/* <Box sx={{ background: "#bebebe36", padding: 2, marginBottom: 2 }}>
        <Label id="demo-simple-select-label">
          <FilterListIcon fontSize="small" />
          Filter
        </Label>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Academic Years</InputLabel>
              <Select
                fullWidth
                name="academicYear"
                value={search.academicYear || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="academic Years"
              >
                {academicYears &&
                  academicYears.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row.academicYearFrom} - {row.academicYearTo}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Class</InputLabel>
              <Select
                fullWidth
                name="className"
                value={search.className || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="Select Class"
              >
                {classes &&
                  classes.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row.className}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Route</InputLabel>
              <Select
                fullWidth
                name="route"
                value={search.route || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="Select Route"
              >
                {routes.map((route) => (
                  <MenuItem value={route._id || ""} key={route._id}>
                    {route.transportRouteTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Stop Name</InputLabel>
              <Select
                fullWidth
                name="stopname"
                value={search.stopname || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="Select Stop Name"
              >
                {routes
                  .filter((route) => route._id === search.route)[0]
                  ?.routeDetails.map((stopName) => (
                    <MenuItem key={stopName._id} value={stopName._id || ""}>
                      {stopName.stopName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Hostel</InputLabel>
              <Select
                fullWidth
                name="hostel"
                value={search.hostel || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="Select Room"
              >
                {hostels &&
                  hostels.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Room</InputLabel>
              <Select
                fullWidth
                name="room"
                value={search.room || ""}
                onChange={(e) => handelSelectSearch(e)}
                label="Select Room"
              >
                {rooms &&
                  rooms.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row.type.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box> */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Description
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Amount
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeCategory
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fees, index) => (
                <TableRow
                  key={fees._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{fees.name}</TableCell>
                  <TableCell align="center">
                    {/* {fees.description} */}

                    {fees.description.length > 50
                      ? `${fees.description.slice(0, 100)}...`
                      : "NA"}
                  </TableCell>
                  <TableCell align="center">{fees.amount}</TableCell>
                  <TableCell align="center">
                    <ShowComponent roleFunction="feeCategory" action="update">
                      <Tooltip title="Update">
                        <IconButton
                          onClick={() => handleFeeCategoryEdit(fees._id, fees)}
                          size="small"
                          style={{ color: "#1b3779" }}
                          variant="contained"
                        >
                          <ModeEditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ShowComponent>
                    <ShowComponent roleFunction="feeCategory" action="delete">
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => setDeleteModal(fees._id, fees)}
                          size="small"
                          variant="contained"
                        >
                          <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={fees._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </ShowComponent>
                    {feeCategory.indexOf(fees) === 0 ? null : (
                      <ShowComponent roleFunction="feeCategory" action="update">
                        <IconButton
                          size="small"
                          onClick={() => handlePriorityUp(index, fees._id)}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      </ShowComponent>
                    )}
                    {feeCategory.indexOf(fees) ===
                    feeCategory.length - 1 ? null : (
                      <ShowComponent roleFunction="feeCategory" action="update">
                        <IconButton
                          size="small"
                          onClick={() => handlePriorityDown(index, fees._id)}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </ShowComponent>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {!feeCategory.length && (
          <MuiTableTypography variant="h6">No data found</MuiTableTypography>
        )}

        {selected.length > 1 && (
          <ShowComponent roleFunction="feeCategory" action="delete">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteMultiple}
            >
              Delete
            </Button>
          </ShowComponent>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={feeCategory.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* Add/Update FeeMap CategoryList============= */}
      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <Box p={2} component="form" onSubmit={handleAddSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MuiText>
                {editFeeCategory
                  ? "Update Fee Category"
                  : "Create New Fee Category"}
              </MuiText>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                required
                fullWidth
                label="Name"
                size="small"
                name="name"
                value={addForm.name || ""}
                onChange={handleAddForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Description"
                size="small"
                name="description"
                value={addForm.description || ""}
                onChange={handleAddForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Amount"
                size="small"
                required
                type="number"
                name="amount"
                value={addForm.amount || ""}
                onChange={handleAddForm}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              }}
              gap={2}
            >
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={handleCloseAddDialog}
              >
                Cancel
              </Button>
              <ShowComponent
                roleFunction="feeCategory"
                action={editFeeCategory ? "update" : "add"}
              >
                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}
                >
                  {editFeeCategory ? "Update Fee Category" : "Add Fee Category"}
                </LoadingButton>
              </ShowComponent>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Wrapper>
  );
}

export default FeeMapCategoryList;
