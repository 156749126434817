import React, { useContext, useEffect, useState } from "react";

import css from "../../../styles/fees/ClassMap.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  DialogTitle,
  FormControl,
  Toolbar,
  Tooltip,
  InputLabel,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Box,
  Typography,
  TablePagination,
} from "@mui/material";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Add } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dayjs from "dayjs";
import SectionSubHeader from "../../SectionSubHeader";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import moment from "moment";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ShowComponent from "../../ShowComponent";
const Reconciliation = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [feeReciepts, setFeeReceipts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllFeeReceipts = async () => {
    try {
      const { data } = await get(urls.receipt.getReceiptsPaidWithCheque, {
        headers: { roleFunction: "reconsiliation" },
      });
      setFeeReceipts(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFeeReceipts();
  }, [selectedSetting._id]);

  const handleClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const ids = feeReciepts.map((fee) => fee._id);
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const updateSingle = async (status, id) => {
    try {
      const { data } = await put(
        `${urls.receipt.updateSingleReceiptReconciliationStatus}${id}`,
        "",
        {
          action: status,
        },
        { headers: { roleFunction: "reconsiliation" } }
      );

      getAllFeeReceipts();
    } catch (error) {
      console.log(error);
    }
  };

  const updateMultiple = async (status) => {
    try {
      const { data } = await put(
        urls.receipt.updateMultipleReceiptReconciliationStatusStatus,
        "",
        {
          receiptIds: selected,
          action: status,
        },
        { headers: { roleFunction: "reconsiliation" } }
      );
      setSelected([]);
      getAllFeeReceipts();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <SectionSubHeader title="Fee Reconciliation" />
      <div style={{ margin: "20px 0" }} className={css.tableContainer}>
        <TableContainer component={Paper}>
          {selected.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0",
              }}
            >
              <Stack direction="row" spacing={2} alignSelf="flex-end">
                <ShowComponent roleFunction="reconsiliation" action="update">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateMultiple("Approve")}
                  >
                    Approve
                  </Button>
                </ShowComponent>
                <ShowComponent roleFunction="reconsiliation" action="update">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => updateMultiple("Reject")}
                  >
                    Reject
                  </Button>
                </ShowComponent>
              </Stack>
            </Box>
          ) : null}

          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  <Checkbox
                    checked={selected.length === feeReciepts.length}
                    indeterminate={
                      selected.length > 0 &&
                      feeReciepts.length > selected.length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Receipt Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Roll Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Amount
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Bank Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Bank Branch
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Cheque Number
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeReciepts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fees, index) => (
                  <TableRow
                    key={fees._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      <Checkbox
                        checked={selected.includes(fees._id)}
                        onChange={(e) => handleClick(e, fees._id)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{fees.receiptNumber}</TableCell>
                    <TableCell align="center">
                      {fees.payeeDetails.name}
                    </TableCell>
                    <TableCell align="center">
                      {fees.payeeDetails.rollNumber}
                    </TableCell>
                    <TableCell align="center">{fees.amountPaid}</TableCell>
                    <TableCell align="center">
                      {fees.chequeDetails.bankName}
                    </TableCell>
                    <TableCell align="center">
                      {fees.chequeDetails.branchName}
                    </TableCell>
                    <TableCell align="center">
                      {fees.chequeDetails.chequeNumber}
                    </TableCell>
                    <TableCell align="center">
                      {moment(new Date(fees.chequeDetails.chequeDate)).format(
                        "DD-MM-YYYY"
                      )}
                    </TableCell>

                    <TableCell align="right">
                      <Stack direction={"row"}>
                        <ShowComponent
                          roleFunction="reconsiliation"
                          action="update"
                        >
                          <IconButton
                            onClick={() => updateSingle("Approve", fees._id)}
                          >
                            <CheckRoundedIcon />
                          </IconButton>
                        </ShowComponent>
                        {fees.reconciliationStatus === "Rejected" ? null : (
                          <ShowComponent
                            roleFunction="reconsiliation"
                            action="update"
                          >
                            <IconButton
                              onClick={() => updateSingle("Reject", fees._id)}
                            >
                              <ClearRoundedIcon color="error" />
                            </IconButton>
                          </ShowComponent>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!feeReciepts.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={feeReciepts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </div>
    </Box>
  );
};

export default Reconciliation;
