import React, { useContext, useState } from "react";
import SectionsSubHeader from "../../../components/SectionSubHeader";

import {
  Box,
  Button,
  Card,
  Fab,
  Dialog,
  FormControl,
  Grid,
  TextareaAutosize,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableContainer,
  Paper,
  IconButton,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import SettingContext from "../../../context/SettingsContext";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { post, put } from "../../../services/apis";
import TimezoneSelect from "react-timezone-select";
import Switch from "@mui/material/Switch";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import "../../../styles/components/Setting/Setting.scss";
import { urls as url } from "../../../services/urlConstant";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import appendStringToUrls from "../../../utils/appendString";

import Wrapper from "../../Wrapper";
import ShowComponent from "../../ShowComponent";

const label = { inputProps: { "aria-label": "Switch demo" } };

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    overflow: "visible",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    color: "#fff",
  },
};
const ManageInstitute = () => {
  const { settings, setSettings, selectedSetting } = useContext(SettingContext);
  const [addDialog, setAddDialog] = useState(false);
  const [settingDetails, setSettingDetails] = useState(null);
  const [schoolCreateLogoLink, setSchoolCreateLogoLink] = useState("");
  const [schoolLogoLink, setSchoolLogoLink] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [sessionStartMonth, setSessionStartMonth] = useState(dayjs(Date.now()));
  const [sessionEndMonth, setSessionEndMonth] = useState(dayjs(Date.now()));
  const [previewUrl, setPreviewUrl] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [previewCreateUrl, setPreviewCreateUrl] = useState(null);
  const [schoolCreateLogo, setSchoolCreatelLogo] = useState("");
  const { setSettingsContext } = useContext(SettingContext);
  const [createDetails, setCreateDetails] = useState({
    regDate: dayjs(Date.now()),
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const resetForm = () => {
    setCreateDetails({});
  };

  const handleToggleActive = async (id) => {
    console.log("yyyyyyy");
    try {
      const res = await put(
        `${urls.settings.toggleStatus}${id}`,
        "",
        {},
        { headers: { roleFunction: "setting" } }
      );
      console.log("status ", res.data.data);
      if (res.data.data) {
        setSettings(
          settings.map((toggles) =>
            id === toggles._id ? res.data.data : toggles
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let addNewSetting = () => {
    navigate("/addSetting");
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Manage Institute" />
      <div style={{ margin: "20px 0" }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  School Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Logo
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settings
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((set, index) => {
                  console.log(set, "set");
                  return (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{set.schoolName}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {set.logo ? (
                            <img
                              src={set.logo.link}
                              alt="logo"
                              height={50}
                              width={50}
                              style={{
                                // borderRadius: "50%",
                                objectFit: "contain",
                              }}
                              onError={(e) =>
                                console.error("Error loading image:", e)
                              }
                            />
                          ) : (
                            <Avatar src="/broken-image.jpg" />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <ShowComponent roleFunction="setting" action="update">
                          <Tooltip title="Update">
                            <IconButton
                              style={{ color: "#1b3779" }}
                              onClick={() =>
                                navigate(`/settings/edit/${set._id}`)
                              }
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Switch
                            onChange={() => handleToggleActive(set._id)}
                            checked={set.active}
                            defaultChecked
                            style={{
                              color: set.active ? "green" : "red",
                            }}
                            color="success"
                            sx={{
                              "& .MuiSwitch-track": {
                                backgroundColor: set.active ? "green" : "red",
                              },
                            }}
                          />
                        </ShowComponent>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={settings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: " flex",
            justifyContent: "flex-start",
            alignItem: "center",
            my: 1,
          }}
        />
      </div>
      <ShowComponent roleFunction="setting" action="add">
        <div className="add-icon">
          <Tooltip title="Add Institute">
            <Fab
              variant="contained"
              onClick={() => addNewSetting()}
              sx={{
                color: "#ffff",
                backgroundColor: "#1b3779",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </ShowComponent>
    </Wrapper>
  );
};

export default ManageInstitute;
