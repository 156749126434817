import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// icons
import { Add } from "@mui/icons-material";
// custome components
import { get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import ShowComponent from "../../ShowComponent";
import FeeMap from "./FeeMap";
import Wrapper from "../../Wrapper";

const MuiStyle = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 3,
}));

const MuiBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

const MuiBoxCell = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
}));

const MuiTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const MuiDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: "flext-end",
  width: "100%",
  alignItems: "center",
  display: "flex",
  columnGap: "10px",
  padding: "10px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReceiptBook2() {
  const theme = useTheme();
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [tabValue, setTabValue] = useState(0);
  const [addDialog, setAddDialog] = useState(false);
  const [name, setName] = useState("");
  const [receipts, setReceipts] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [selectedClassMap, setSelectedClassMap] = useState();
  const [loader, setLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==========================

  const handleClassMap = (id) => {
    setSelectedClassMap(id);
    setTabValue(1);
  };

  useEffect(() => {
    getAllReceipts();
  }, [selectedSetting._id]);

  const getAllReceipts = async () => {
    try {
      const { data: allReceipts, status } = await get(
        `${urls.receiptTitle.list}`,
        {
          headers: { roleFunction: "receiptTitle" },
        }
      );
      if (status > 199 && status < 299) {
        setReceipts(allReceipts.data);
      }
    } catch (error) {
      return error;
    }
  };

  console.log(receipts, "recipts");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseAddDialog = () => {
    setName("");
    setIsEdit(null);
    setAddDialog(false);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const handleAddNewReceipt = async () => {
    setLoader(true);
    try {
      if (!isEdit) {
        const { status } = await post(
          `${urls.receiptTitle.create}`,
          {
            name,
          },
          { headers: { roleFunction: "receiptTitle" } }
        );
        if (status > 199 && status < 299) {
          await getAllReceipts();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.receiptTitle.update}${isEdit}`,
          "",
          {
            name,
          },
          { headers: { roleFunction: "receiptTitle" } }
        );
        if (status > 199 && status < 299) {
          await getAllReceipts();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
    setLoader(false);
  };

  const handleEdit = async (id, name) => {
    setIsEdit(id);
    setName(name);
    handleOpenAddDialog();
  };

  const handleReceiptStatus = async (id) => {
    try {
      const { status } = await put(
        `${urls.receiptTitle.updateStatus}${id}`,
        "",
        {},
        { headers: { roleFunction: "receiptTitle" } }
      );
      if (status > 199 && status < 299) {
        await getAllReceipts();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <SectionSubHeader title="Receipt Book" />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="RECEIPT BOOK" {...a11yProps(0)} />
          <Tab label="FEE MAP" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <MuiBox my={2}>
          <ShowComponent roleFunction="receiptTitle" action="add">
            <Button onClick={handleOpenAddDialog} variant="contained">
              <Add /> Add receipt
            </Button>
          </ShowComponent>
        </MuiBox>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Receipt Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((receipt, index) => (
                  <TableRow
                    key={receipt._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{receipt.name}</TableCell>
                    <TableCell align="center">
                      <MuiBoxCell>
                        <ShowComponent
                          roleFunction="receiptTitle"
                          action="update"
                        >
                          <Button
                            onClick={() =>
                              handleEdit(receipt._id, receipt.name)
                            }
                            size="small"
                            variant="contained"
                          >
                            edit
                          </Button>
                        </ShowComponent>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => handleClassMap(receipt._id)}
                        >
                          Fee map
                        </Button>

                        <ShowComponent
                          roleFunction="receiptTitle"
                          action="update"
                        >
                          <Button
                            color={!receipt.active ? "success" : "error"}
                            onClick={() => handleReceiptStatus(receipt._id)}
                            size="small"
                            variant="contained"
                          >
                            {receipt.active ? "Deactivate" : "Activate"}
                          </Button>
                        </ShowComponent>
                      </MuiBoxCell>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!receipts.length && <MuiTypography>No data found</MuiTypography>}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={receipts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <FeeMap selectedClassMap={selectedClassMap} />
      </TabPanel>

      {/* === Add/Update Receipt Book ========= */}
      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <MuiStyle>
          <DialogTitle sx={{ fontWeight: "bold" }}>
            {isEdit ? "Update Receipt Name" : "Add New Receipt Name"}
          </DialogTitle>
          <DialogContent>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              margin="dense"
              label="Receipt Name"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <MuiDialogActions>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleCloseAddDialog}
            >
              Cancel
            </Button>

            <LoadingButton
              loading={loader}
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              onClick={handleAddNewReceipt}
              sx={{
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
            >
              {isEdit ? "Update" : "Add"}
            </LoadingButton>
          </MuiDialogActions>
        </MuiStyle>
      </Dialog>
      {/* =========================== */}
    </Wrapper>
  );
}
