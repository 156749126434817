import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import MovingText from "react-moving-text";

import Footer from "../Components2/Footer";
import NavTopnav from "../components/NavTopnav/NavTopnav";

const VisionandMission = () => {
  // const styles = {
  //   background: {
  //     // backgroundImage: "url(/course1.png)",
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "cover",
  //     width: "100%",
  //     opacity: "1",
  //     height: "100%",
  //     position: "absolute",
  //     top: 0,
  //   },
  // };

  const OuterBox = styled(Box)(({ theme }) => ({
    backgroundImage:
      "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://img.freepik.com/free-photo/businessman-big-office_53876-144319.jpg)",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "100% 100%",
    paddingTop: "150px",
  }));

  const ContentContainer = styled(Container)(({ theme }) => ({
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 15px",
    },
  }));

  return (
    <>
      <NavTopnav />
      <OuterBox>
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // marginTop: "180px",
            }}
          >
            <Grid item xs={12} md={10}>
              <Typography
                variant="h4"
                sx={{
                  color: "#F86F03",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                VISION
              </Typography>
              <br />
              <ContentContainer>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    fontSize: "16px",
                    // marginLeft: "5%",
                    // marginRight: "5%",
                  }}
                >
                  To become a leading centre of excellence in education and
                  learning and to provide high quality, value based education to
                  all through various modes to meet the global challenges.
                </Typography>
              </ContentContainer>
            </Grid>
            <br />

            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  color: "#F86F03",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                MISSION
              </Typography>
              <br />

              <ContentContainer>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    fontSize: "16px",
                    // marginLeft: "5%",
                    // marginRight: "5%",
                  }}
                >
                  <Typography component="ul">
                    <Typography component="li" gutterBottom>
                      Provide access to education for all.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      Provide an atmosphere of academic growth and value system
                      based on honesty, truth and integrity.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      Foster creativity in teaching and learning.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      Development of human resources to meet the needs of the
                      country.
                    </Typography>
                    <Typography component="li" gutterBottom>
                      To build knowledge based society.
                    </Typography>
                  </Typography>
                </Typography>
              </ContentContainer>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </OuterBox>
    </>
  );
};

export default VisionandMission;
