import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { get } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { useEffect, useContext } from "react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";

import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";

dayjs.extend(localeData);

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    // m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    // marginTop: "10px",
  },
};

const AttendanceReport = () => {
  const { selectedSetting } = useContext(SettingContext);
  const [reportDetails, setReportDetails] = useState({});
  const [sections, setSections] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [attendanceReports, setAttendanceReports] = useState([]);
  const [reportPdfLoader, setReportPdfLoader] = useState(false);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [sort, setSort] = useState({
    name: "asc",
    workingDays: "asc",
    presentDays: "asc",
    percentage: "asc",
  });

  let [loader, setLoader] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==============

  const sortByName = () => {
    console.log("Name");
    if (sort.name === "asc") {
      let sortedList = [
        ...attendanceReports.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setAttendanceReports(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...attendanceReports.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setAttendanceReports(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByWorkingDays = () => {
    console.log("workingDays");
    if (sort.workingDays === "asc") {
      let sortedList = [
        ...attendanceReports.sort((a, b) => b.workingDays - a.workingDays),
      ];

      setAttendanceReports(sortedList);
      setSort({ ...sort, workingDays: "des" });
    } else {
      let sortedList = [
        ...attendanceReports.sort((a, b) => a.workingDays - b.workingDays),
      ];
      setAttendanceReports(sortedList);
      setSort({ ...sort, workingDays: "asc" });
    }
  };

  const sortByPresentDays = () => {
    console.log("PresentDays");
    if (sort.presentDays === "asc") {
      let sortedList = [
        ...attendanceReports.sort((a, b) => b.presentDays - a.presentDays),
      ];

      setAttendanceReports(sortedList);
      setSort({ ...sort, presentDays: "des" });
    } else {
      let sortedList = [
        ...attendanceReports.sort((a, b) => a.presentDays - b.presentDays),
      ];
      setAttendanceReports(sortedList);
      setSort({ ...sort, presentDays: "asc" });
    }
  };

  const sortByPercentage = () => {
    console.log("Percentage");
    if (sort.percentage === "asc") {
      let sortedList = [
        ...attendanceReports.sort((a, b) => b.percentage - a.percentage),
      ];

      setAttendanceReports(sortedList);
      setSort({ ...sort, percentage: "des" });
    } else {
      let sortedList = [
        ...attendanceReports.sort((a, b) => a.percentage - b.percentage),
      ];
      setAttendanceReports(sortedList);
      setSort({ ...sort, percentage: "asc" });
    }
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setReportDetails({ ...reportDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "studentAttendance" },
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "studentAttendance" },
        });
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "studentAttendance" },
        });
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting]);

  const handleSections = (e) => {
    const { name, value } = e.target;
    setReportDetails({ ...reportDetails, [name]: value });
  };

  const handleAttendanceReportSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const studentAttendanceReportRes = await get(
        `${urls.students.getStudentAttendanceReport}/${reportDetails.academicYear}/${reportDetails.class}/${reportDetails.section}/${reportDetails.month}`,
        { headers: { roleFunction: "studentAttendance" } }
      );
      if (studentAttendanceReportRes.data.success) {
        setAttendanceReports(studentAttendanceReportRes.data.data);
      } else {
        console.log(studentAttendanceReportRes);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const handleReportPdf = async () => {
    setReportPdfLoader(true);
    try {
      const getReportPdf = await get(
        `${urls.students.getStudentsMonthlyAttendanceReport}/${reportDetails.academicYear}/${reportDetails.class}/${reportDetails.section}/${reportDetails.month}`,
        {
          headers: { roleFunction: "studentAttendance" },
          responseType: "blob",
        }
      );
      if (getReportPdf.status === 200) {
        const uri = URL.createObjectURL(getReportPdf.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "AttendanceReport.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
    setReportPdfLoader(false);
  };

  return (
    <div>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleAttendanceReportSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="academicYear"
                    onChange={(e) => handleSections(e)}
                    value={reportDetails.academicYear}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    label="Class"
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="class"
                    value={reportDetails.class}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Section
                  </InputLabel>
                  <Select
                    label="Section"
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="section"
                    value={reportDetails.section}
                    onChange={(event) => handleSections(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Month
                  </InputLabel>
                  <Select
                    label="Month"
                    labelId="demo-simple-select-filled-label"
                    required
                    id="demo-simple-select-filled"
                    name="month"
                    value={reportDetails.month}
                    onChange={(event) => handleSections(event)}
                  >
                    {dayjs.months().map((month, index) => (
                      <MenuItem
                        key={month}
                        value={month}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                }}
              >
                <LoadingButton
                  loading={loader}
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    "&:hover": {
                      background: "#1b3779",
                    },
                  }}
                  size="small"
                  type="submit"
                >
                  Find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      <div className={style.iconContainer}>
        {reportDetails.academicYear &&
          reportDetails.section &&
          reportDetails.class &&
          reportDetails.month &&
          (reportPdfLoader ? (
            <CircularProgress />
          ) : (
            <Tooltip title="Print">
              <Button onClick={handleReportPdf}>
                <PrintIcon />
              </Button>
            </Tooltip>
          ))}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead style={{ background: "rgb(27 55 121)" }}>
            <TableRow>
              <TableCell align="center">
                <span className="class-table-header">Student</span>
                <span
                  onClick={sortByName}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.name === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Working Days</span>
                <span
                  onClick={sortByWorkingDays}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.workingDays === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Present Days</span>
                <span
                  onClick={sortByPresentDays}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.presentDays === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Percentage</span>
                <span
                  onClick={sortByPercentage}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  {sort.percentage === "asc" ? "▲" : "▼"}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceReports.length > 0 &&
              attendanceReports
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((attendanceReport, index) => (
                  <TableRow
                    key={attendanceReport._id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceReport.basicInfo.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {attendanceReport.workingDays}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceReport.presentDays}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceReport.percentage !== undefined
                        ? attendanceReport.percentage
                        : 0}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!attendanceReports.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={attendanceReports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />
    </div>
  );
};

export default AttendanceReport;
