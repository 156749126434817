import React, { useContext, useEffect } from "react";
// style
import css from "../../../styles/fees/QuickFeeCollection.module.css";
import { useState } from "react";
import { get, post } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import { instance } from "../../../services/axios.Config";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { LoadingButton } from "@mui/lab";
import QuickFeeConcessionModal from "../Fees/components/QuickFeeConcessionModal";
import QuickFeeCollectModal from "../Fees/components/QuickFeeCollectModal";

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (["class"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class`;
      result.push(newItem);
    } else if (["classOld"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-Old`;
      result.push(newItem);
    } else if (["classNew"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-New`;
      result.push(newItem);
    } else if (dep === "hostel") {
      let newItem = `[${data.hostel?.name}]-Hostel`;
      result.push(newItem);
    } else if (dep == "transport") {
      let newItem = `[${data.route.transportRouteTitle}]--[${data.stop.stopName}]--[${data.pickType}]`;
      result.push(newItem);
    } else if (dep == "pickType") {
      let newItem = `[${data.pickType}]-Pick_Type`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

function CollectFeeDetails() {
  const { selectedSetting } = useContext(SettingContext);

  const [formData, setFormData] = useState({});
  const [students, setStudents] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);
  const [feeReceipt, setFeeReceipt] = useState(null);
  const [allPastDue, setAllPastDue] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [concessionDialog, setConcessionDialog] = useState(false);
  const [concession, setConcession] = useState(null);
  const [collectFeeModal, setCollectFeeModal] = useState(false);
  const [receiptBooks, setReceiptBooks] = useState([]);
  const [categoryFeesList, setCategoryFeesList] = useState([]);
  const [collectingFee, setCollectingFee] = useState(false);
  const [downloadingPreview, setDownloadingPreview] = useState(false);
  const [findLoader, setFindLoader] = useState(false);
  const [note, setNote] = useState("");
  const options = ["Miscellaneous", "Penalty"];
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [receiptTitles, setReceiptTitles] = useState([]);
  const [feeMaps, setFeeMaps] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [feeDetails, setFeeDetails] = useState(null);
  const [paymentData, setPaymentData] = useState({});
  const [totalAmountToBePaid, setTotalAmountToBePaid] = useState(0);
  const [selectedPastDueIds, setSelectedPastDueIds] = useState([]);
  const [downloadingReceipt, setDownloadingReceipt] = useState(false);
  const [paidAt, setPaidAt] = useState(Date.now());
  const [routes, setRoutes] = useState([]);

  const getAllReceiptTitles = async () => {
    try {
      const { data } = await get(urls.receiptTitle.list, {
        headers: { roleFunction: "collectFees" },
        params: {
          search: { active: true },
        },
      });
      // console.log(data.data, "all receipt titles");
      setReceiptTitles(data.data);
      setFormData((prev) => ({
        ...prev,
        receiptTitleId: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // get class
  const getClasses = async () => {
    try {
      const res = await get(urls.class.getAllClass, {
        headers: { roleFunction: "collectFees" },
      });
      setClasses(res.data.data);

      setFormData((prev) => ({
        ...prev,
        class: res.data.data[0] ? res.data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getRoutes = async () => {
    try {
      const { data } = await get(urls.route.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setRoutes(data.data);
      setFormData((prev) => ({
        ...prev,
        route: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.error(error);
    }
  };

  // get all classes
  useEffect(() => {
    getClasses();
    getAllReceiptTitles();
    getRoutes();
  }, [selectedSetting._id]);

  // get section
  const getSections = async () => {
    try {
      const { data } = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "collectFees" },
        params: { search: { class: formData.class } },
      });
      setSections(data.data);
      setFormData((prev) => ({
        ...prev,
        section: data.data[0] ? data.data[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.class) {
      getSections();
    }
  }, [formData.class, selectedSetting._id]);

  // all fee map
  const getAllFeeMaps = async () => {
    try {
      let filter = {
        active: true,
      };

      if (
        !["hostel fee", "transport"].includes(
          receiptTitles
            .find((r) => r._id == formData.receiptTitleId)
            ?.name?.toLowerCase()
        )
      ) {
        filter["class"] = formData.class;
      }
      console.log(filter, "filter");
      const { data } = await get(
        urls.feeMap.list + "/" + formData.receiptTitleId,
        {
          headers: { roleFunction: "collectFees" },
          params: { search: { ...filter } },
        }
      );

      let allFeeMaps = data.data;
      if (
        receiptTitles.find((r) => r._id === formData.receiptTitleId)?.name ===
        "Transport"
      ) {
        allFeeMaps = allFeeMaps.filter((f) => f.route?._id === formData.route);
      }

      setFeeMaps(allFeeMaps);
      setFormData((prev) => ({
        ...prev,
        feeMapId: allFeeMaps[0] ? allFeeMaps[0]._id : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.class && formData.receiptTitleId) {
      getAllFeeMaps();
    }
  }, [
    formData.class,
    formData.receiptTitleId,
    selectedSetting._id,
    formData.route,
  ]);

  // get all students
  const getAllStudents = async () => {
    try {
      let filter = {
        classId: formData.class,
        sectionId: formData.section,
        feeMapId: formData.feeMapId,
      };

      const { data: students, status } = await get(
        `${urls.receipt.getStudentList}`,
        {
          headers: { roleFunction: "collectFees" },
          params: {
            ...filter,
          },
        }
      );

      if (status > 199 && status < 299) {
        setSelectStudent(null);
        console.log(students.data, "datata================================");
        setStudents(students.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.feeMapId && formData.class && formData.section) {
      getAllStudents();
    } else {
      setStudents([]);
      setSelectStudent(null);
    }
  }, [
    formData.feeMapId,
    formData.class,
    formData.section,
    selectedSetting._id,
  ]);

  // form data to fetch the fee details
  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //
  const handleStudentSelect = (e, val) => {
    setSelectStudent(val);
    setItemDetails([]);
    setFeeDetails(null);
    setPaymentData({});
  };

  // get student fee Details
  const handleSubmitFind = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setFindLoader(true);

    try {
      const { data: feeReceipt, status } = await get(
        `${urls.receipt.getFeeDetails}`,
        {
          headers: { roleFunction: "collectFees" },
          params: {
            feeMapId: formData.feeMapId,
            studentId: selectStudent._id,
            receiptTitleId: formData.receiptTitleId,
            installmentId:
              formData.installmentId ||
              feeMaps.filter((f) => f._id == formData.feeMapId)[0]
                ?.installments[0]._id,
          },
        }
      );
      if (status > 199 && status < 299) {
        console.log(feeReceipt.data, "data==========");
        setFeeDetails(feeReceipt.data);
        setItemDetails(
          feeReceipt.data.feeMapCategories.map((d) => ({
            name: d.name,
            amount: Number(d.amount),
            description: d.description,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
    setFindLoader(false);
  };

  useEffect(() => {
    if (formData.installmentId) {
      handleSubmitFind();
    }
  }, [formData.installmentId]);

  // add/remove/update item details
  const handleAddedRowsChange = (e, changeIndex) => {
    const { name, value } = e.target;
    setItemDetails((prev) =>
      prev.map((itemDetail, index) =>
        index === changeIndex
          ? {
              ...itemDetail,
              [name]: name === "amount" ? Number(value) : value,
            }
          : itemDetail
      )
    );
  };

  const handleAddRow = () => {
    setItemDetails((prev) => [
      ...prev,
      {
        name: "",
        amount: 0,
      },
    ]);
  };

  const handleRemoveAddedRow = (removeIndex) => {
    setItemDetails((prev) =>
      prev.filter((ele, index) => removeIndex !== index)
    );
  };

  // concessions manipulation

  const handleConcessionDialogClose = () => {
    setConcessionDialog(false);
  };
  const handleConcessionDialogOpen = () => {
    if (concession) {
      setConcession(null);
    } else {
      setConcessionDialog(true);
    }
  };
  const handleCollectFeeDialogClose = () => {
    setCollectFeeModal(false);
  };
  const handleCollectFeeDialogOpen = () => {
    setCollectFeeModal(true);
    let totalAmount = 0;
    if (feeDetails.pastDues.length) {
      totalAmount = feeDetails.pastDues.reduce(
        (total, current) => total + Number(current.amount),
        0
      );
    }
    totalAmount += itemDetails.reduce(
      (total, current) => total + Number(current.amount),
      0
    );
    setTotalAmountToBePaid(totalAmount);
  };
  const handleConcessionSubmit = (data) => {
    setConcession(data);
    handleConcessionDialogClose();
  };

  const handleFeeCollect = async (paymentMode, paymentDetails) => {
    setCollectingFee(true);
    const data = {
      feeMapId: formData.feeMapId,
      studentId: selectStudent._id,
      paidAt: paidAt
        ? dayjs(paidAt).format("YYYY/MM/DD")
        : dayjs(Date.now()).format("YYYY/MM/DD"),
      installmentId:
        formData.installmentId ||
        feeMaps.filter((f) => f._id == formData.feeMapId)[0]?.installments[0]
          ?._id,
      items: itemDetails,
      note,
      pastDueIds: selectedPastDueIds,
      paymentMode: paymentMode.payment,
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
    };

    if (paymentMode.payment === "Cash") {
      data.cashDetails = {
        note: paymentMode.note,
        date: paymentMode.date,
      };
    }
    if (paymentMode.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (paymentMode.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (paymentMode.payment === "Upi") {
      data.upiDetails = {
        upiApp: paymentDetails.upiApp,
        utrNumber: paymentDetails.utrNumber,

        // bankName: paymentDetails.bankName,
        // refNumber: paymentDetails.refNumber,
        // paidByName: paymentDetails.paidByName,
      };
    }
    if (paymentMode.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (paymentMode.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }

    try {
      const response = await instance({
        method: "post",
        url: `${urls.receipt.create}`,
        data,
        responseType: "blob",
        headers: { roleFunction: "collectFees" },
      });
      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
        }
        setConcession(null);
        setFormData((prev) => ({ ...prev, installmentId: "" }));
        setSelectStudent(null);
        setFeeDetails(null);
        setCollectingFee(false);
        setCollectFeeModal(false);
        setSelectedPastDueIds([]);
        setItemDetails([]);
        setPaidAt(null);
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.receipt.create}`,
          data,
          headers: { roleFunction: "collectFees" },
        });
        setCollectingFee(false);
      }
    } catch (error) {
      console.log(error);
      setCollectingFee(false);

      if (error.response) {
        let jsonBlob = new Blob([error.response.data], {
          type: "application/json",
        });
        const jsonErr = JSON.parse(await jsonBlob.text());
        if (jsonErr.message === "jwt expired") {
          setCollectingFee(false);

          toast.error("Session expired! Please login again!");
          window.location.href = "/login";

          return;
        }
        toast.error(jsonErr.message);
      } else {
        toast.error(error);
      }
    }
    setCollectingFee(false);
  };

  useEffect(() => {
    setConcession(null);
    setFormData((prev) => ({ ...prev, installmentId: "" }));
    setFeeDetails(null);
    setCollectingFee(false);
    setCollectFeeModal(false);
    setSelectedPastDueIds([]);
    setItemDetails([]);
  }, [selectStudent]);

  const handlePreviewButtonClick = async (paymentMode, paymentDetails) => {
    setDownloadingPreview(true);
    const data = {
      feeMapId: formData.feeMapId,
      studentId: selectStudent._id,
      paidAt: paidAt
        ? dayjs(paidAt).format("YYYY/MM/DD")
        : dayjs(Date.now()).format("YYYY/MM/DD"),
      installmentId:
        formData.installmentId ||
        feeMaps.filter((f) => f._id == formData.feeMapId)[0]?.installments[0]
          ?._id,
      items: itemDetails,
      pastDueIds: selectedPastDueIds,
      paymentMode: paymentMode.payment,
      note,
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
    };

    if (paymentMode.payment === "Cash") {
      data.cashDetails = {
        note: paymentMode.note,
        date: paymentMode.date,
      };
    }
    if (paymentMode.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (paymentMode.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (paymentMode.payment === "Upi") {
      data.upiDetails = {
        upiApp: paymentDetails.upiApp,
        utrNumber: paymentDetails.utrNumber,
        // bankName: paymentDetails.bankName,
        // refNumber: paymentDetails.refNumber,
        // paidByName: paymentDetails.paidByName,
      };
    }
    if (paymentMode.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (paymentMode.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }

    try {
      const response = await instance({
        method: "post",
        url: `${urls.receipt.previewReceipt}`,
        data,
        responseType: "blob",
        headers: { roleFunction: "collectFees" },
      });
      if (response) {
        if (response.status > 199 && response.status < 299) {
          const uri = URL.createObjectURL(response.data);
          window.open(uri, "__blank");
        }
        setDownloadingPreview(false);
      } else {
        const response = await instance({
          method: "post",
          url: `${urls.receipt.previewReceipt}`,
          data,
          headers: { roleFunction: "collectFees" },
        });
        setDownloadingPreview(false);
      }
    } catch (error) {
      console.log(error);
      setDownloadingPreview(false);

      if (error.response) {
        let jsonBlob = new Blob([error.response.data], {
          type: "application/json",
        });
        const jsonErr = JSON.parse(await jsonBlob.text());
        if (jsonErr.message === "jwt expired") {
          setDownloadingPreview(false);

          toast.error("Session expired! Please login again!");
          window.location.href = "/login";

          return;
        }
        toast.error(jsonErr.message);
      } else {
        toast.error(error);
      }
    }
    setDownloadingPreview(false);
  };

  const handleRowChange = (index, newValue) => {
    const updatedData = [...itemDetails];
    updatedData[index].name = newValue;
    setItemDetails(updatedData);
  };

  const downloadReceipt = async (e, id) => {
    e.preventDefault();
    try {
      setDownloadingReceipt(id);
      const getPrint = await get(`${urls.receipt.downloadFeeReceipt}${id}`, {
        headers: { roleFunction: "collectFees" },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
    setDownloadingReceipt("");
  };

  return (
    <Box p={"1rem"}>
      <SectionSubHeader title="Collect Fees" />
      <Card sx={{ mt: 3, padding: 1 }}>
        <Box sx={{ padding: "10px 0" }}>
          <form onSubmit={handleSubmitFind}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Receipt Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="receiptTitleId"
                    label="Receipt Name"
                    required
                    value={formData.receiptTitleId || ""}
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {receiptTitles.map((receiptTitle, index) => (
                      <MenuItem key={index} value={receiptTitle._id}>
                        {receiptTitle.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    // disabled={
                    //   formData.feeMapId &&
                    //   feeMaps
                    //     .filter((f) => f._id == formData.feeMapId)[0]
                    //     ?.dependencies.includes("class")
                    // }
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="class"
                    label="Class"
                    required
                    value={formData.class || ""}
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    label="Section"
                    value={formData.section || ""}
                    required
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {receiptTitles.find((r) => r._id === formData.receiptTitleId)
                ?.name === "Transport" ? (
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl size="small" sx={styles.textfield} required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Route
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="route"
                      label="Select Route"
                      required
                      value={formData.route || ""}
                      onChange={(event) => handleFormDataInputChange(event)}
                    >
                      {routes &&
                        routes.map((route, index) => (
                          <MenuItem
                            key={route._id}
                            value={route._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {route.transportRouteTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} md={4} lg={4}>
                <FormControl size="small" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Fee Map
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="feeMapId"
                    label="Receipt"
                    required
                    value={formData.feeMapId || ""}
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {feeMaps &&
                      feeMaps.map((feeMap, index) => (
                        <MenuItem
                          key={feeMap._id}
                          value={feeMap._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {showInfo(feeMap)} (₹
                          {feeMap.fee})
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  size="small"
                  value={selectStudent}
                  sx={styles.textfield}
                  onChange={handleStudentSelect}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  options={students}
                  getOptionLabel={(option) =>
                    `${option.basicInfo.name} | ${option.academicInfo.admissionNo} | ${option.academicInfo.rollNo}`
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      {...params}
                      label="Students"
                      placeholder="Search student with name"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                alignSelf="center"
                display="flex"
                justifyContent="flex-start"
              >
                <LoadingButton
                  loading={findLoader}
                  style={styles.Button}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  find
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      {selectStudent && feeDetails && (
        <>
          <Card sx={{ padding: "10px", margin: "10px 0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography fontSize="15px">
                  <b>Student Name: </b> {selectStudent?.basicInfo?.name}
                </Typography>
                <FormControl size="small" sx={{ width: 300, mt: 1 }} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Select Installment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="installmentId"
                    label="Select Installment"
                    value={
                      formData.installmentId ||
                      feeMaps.filter((f) => f._id == formData.feeMapId)[0]
                        ?.installments[0]?._id
                    }
                    required
                    onChange={(event) => handleFormDataInputChange(event)}
                  >
                    {feeDetails.feeMap.installments.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {index + 1}-{row.amount}-
                        {feeDetails.previousReceipts.filter(
                          (r) => r.installmentPaid == row._id
                        )[0]
                          ? "Paid"
                          : "Not-Paid"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography sx={{ fontSize: "14px", margin: "2px 0 0 0" }}>
                  Due Date :{" "}
                  {formData.installmentId
                    ? dayjs(
                        feeDetails.feeMap.installments.filter(
                          (i) => i._id == formData.installmentId
                        )[0]?.dueDate
                      )
                        .toDate()
                        .toLocaleDateString()
                    : dayjs(
                        feeDetails.feeMap.installments.filter(
                          (i) => i._id == feeDetails.feeMap.installments[0]?._id
                        )[0]?.dueDate
                      )
                        .toDate()
                        .toLocaleDateString()}
                </Typography>
              </Box>

              <Box>
                {/* <Typography fontSize="15px">
                  <b>Past Due :</b> ₹{" "}
                  <span>
                    {feeDetails.pastDues
                      .reduce((total, current) => total + current.amount, 0)
                      .toFixed(2)}
                  </span>
                </Typography> */}
                <Typography fontSize="15px">
                  <b>Total Due :</b> ₹{" "}
                  <span>{feeDetails.totalDue.toFixed(2)}</span>
                </Typography>
                <Typography fontSize="15px">
                  <b>Current Due :</b> ₹{" "}
                  <span>{feeDetails.currentDue.toFixed(2)}</span>
                </Typography>
              </Box>
            </div>
          </Card>

          {!feeDetails.previousReceipts.length ? null : (
            <Card
              sx={{ padding: "10px", margin: "10px 0" }}
              className={css.dueInfoContainer}
            >
              <Typography fontSize="15px" mb={1}>
                <b>Previous Receipts:</b> PP - Partially Paid, PPD - Partial
                Payment Done, FP - Full Payment
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  columnGap: "5px",
                }}
              >
                {feeDetails.previousReceipts.map((p) => (
                  <LoadingButton
                    loading={downloadingReceipt === p._id}
                    size="small"
                    variant="outlined"
                    disabled={downloadingReceipt ? true : false}
                    key={p._id}
                    onClick={(e) => downloadReceipt(e, p._id)}
                    endIcon={<DownloadForOfflineSharpIcon />}
                  >
                    {dayjs(p.paidAt).toDate().toLocaleDateString()}-
                    {p.partiallyPaid
                      ? p.partialPaymentCompleted
                        ? "PPD"
                        : "PP"
                      : "FP"}
                  </LoadingButton>
                ))}
              </Box>
            </Card>
          )}

          {!feeDetails?.pastDues?.length ? null : (
            <Box>
              <Typography fontSize="20px">Past Dues</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#1b3779" }}>
                    <TableRow>
                      <TableCell sx={{ color: "white" }} align="center">
                        Installment
                      </TableCell>
                      <TableCell sx={{ color: "white" }} align="center">
                        Due Amount
                      </TableCell>
                      <TableCell sx={{ color: "white" }} align="center">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feeDetails.pastDues.map((itemDetail, index) => (
                      <TableRow key={itemDetail._id}>
                        <TableCell align="center">
                          {feeDetails.feeMap.installments.indexOf(
                            feeDetails.feeMap.installments.filter(
                              (i) => i._id == itemDetail.installmentId
                            )[0]
                          ) + 1}
                        </TableCell>
                        <TableCell align="center">
                          {itemDetail.amount.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            onChange={() => {
                              if (
                                selectedPastDueIds.filter(
                                  (p) => p == itemDetail._id
                                )[0]
                              ) {
                                setSelectedPastDueIds(
                                  selectedPastDueIds.filter(
                                    (p) => p != itemDetail._id
                                  )
                                );
                              } else {
                                setSelectedPastDueIds([
                                  ...selectedPastDueIds,
                                  itemDetail._id,
                                ]);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <div className={css.tableContainer}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: "#1b3779" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Category
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Description
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Amount
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemDetails.map((itemDetail, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          disabled={
                            (itemDetail.name &&
                              itemDetail.name === "Penalty") ||
                            (itemDetail.name &&
                              itemDetail.name === "Miscellaneous")
                              ? true
                              : false
                          }
                          value={itemDetail.name || ""}
                          onChange={(event, newValue) => {
                            handleRowChange(index, newValue);
                          }}
                          options={options}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              value={itemDetail.name || ""}
                              onChange={(event) => {
                                handleRowChange(index, event.target.value);
                              }}
                              placeholder="Type here.."
                              {...params}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          size="small"
                          type="text"
                          name="description"
                          value={itemDetail.description || ""}
                          onChange={(e) => handleAddedRowsChange(e, index)}
                          placeholder="Descriptions"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          size="small"
                          type="number"
                          value={itemDetail.amount || "0"}
                          name="amount"
                          onChange={(e) => handleAddedRowsChange(e, index)}
                          placeholder="Amount"
                          InputProps={{
                            startAdornment: (
                              <CurrencyRupeeIcon fontSize="small" />
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => handleRemoveAddedRow(index)}
                          >
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className={css.addRowBtn}>
                <div>
                  <Button
                    onClick={handleAddRow}
                    variant="contained"
                    size="small"
                  >
                    add row
                  </Button>
                </div>
                <div className={css.addRowBtn_childFlex}>
                  <div>
                    <Button
                      onClick={handleConcessionDialogOpen}
                      variant="contained"
                      size="small"
                    >
                      {concession ? "remove concession" : "add concession"}
                    </Button>
                    <div className={css.concessionText}>
                      {concession
                        ? concession?.format === "Value"
                          ? `Concession:- ₹${concession?.concession}`
                          : `Concession:- ${concession?.concession}%`
                        : ""}
                    </div>
                  </div>
                  <Button
                    onClick={handleCollectFeeDialogOpen}
                    variant="contained"
                    size="small"
                    sx={{
                      background: "#1B3779",
                      "&:hover": {
                        background: "#1B3779",
                      },
                    }}
                  >
                    collect fee
                  </Button>
                </div>
              </div>
            </TableContainer>
          </div>
        </>
      )}
      <QuickFeeConcessionModal
        onSubmit={handleConcessionSubmit}
        open={concessionDialog}
        onClose={handleConcessionDialogClose}
        opener={handleConcessionDialogOpen}
      />
      <QuickFeeCollectModal
        currentDue={totalAmountToBePaid}
        collectingFee={collectingFee}
        downloadingPreview={downloadingPreview}
        onPreviewButtonClick={handlePreviewButtonClick}
        feeReceipt={feeDetails}
        onSubmit={handleFeeCollect}
        open={collectFeeModal}
        onClose={handleCollectFeeDialogClose}
        note={note}
        setNote={setNote}
        paidAt={paidAt}
        setPaidAt={setPaidAt}
      />
    </Box>
  );
}

export default CollectFeeDetails;
