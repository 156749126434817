import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Carousel from "react-slick";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/gallery.module.css";

import GallerySubHome from "./GallerySubHome";
import NavBar from "./navbar/Navbar";

import SettingContext from "../context/SettingsContext";
import appendStringToUrls from "../utils/appendString";
import { get } from "../services/apis";
import { urls as url } from "../services/urlConstant";
import NavTopnav from "./NavTopnav/NavTopnav";
import { settings } from "../Data/carousal";

// icons
import CloseIcon from "@mui/icons-material/Close";
import Footer from "./Footer/Footer";
// import Footer from "../Components2/Footer";

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: "auto",
  background: "#fff",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const GridBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "300px 300px 300px 300px",
  justifyContent: "space-evenly",
  gap: "15px",
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: "300px 300px  ",
  },
  [theme.breakpoints.down(660)]: {
    gridTemplateColumns: "400px   ",
  },
  [theme.breakpoints.down(400)]: {
    gridTemplateColumns: "300px   ",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginTop: "2rem",
  color: "#FF6D34",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const SecondHeading = styled(Typography)(({ theme }) => ({
  color: "#f86f03",
  textShadow: "3px 3px black",
  fontWeight: "bold",
  fontSize: "2.5rem",
  fontFamily: "sans-serif",
  marginTop: "0.8rem",
}));

const LibraryTitleContainer = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.4)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const LibraryTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  paddingTop: "20px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

export default function Slider() {
  let sliderRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFilter, setSelectedFilter] = React.useState("All");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [galleryData, setGalleryData] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("ALL");
  const [filteredGalleryData, setFilteredGalleryData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);

  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    imageData: [],
    viewSingleImg: {},
  });
  // console.log(modalOpen, "modalOpen");

  const SelectedImageIndex = modalOpen.imageData.findIndex(
    (img) => img._id === modalOpen.viewSingleImg._id
  );

  const cutailImages = [
    modalOpen.viewSingleImg,
    ...modalOpen.imageData.slice(0, SelectedImageIndex),
    ...modalOpen.imageData.slice(SelectedImageIndex + 1),
  ];

  // console.log(cutailImages, "==cutailImages");
  // filter gallery based on title change
  React.useEffect(() => {
    if (selectedFilter === "All") {
      setFilteredData(galleryData);
    } else {
      setFilteredData(galleryData.filter((d) => d.title == selectedFilter));
    }
  }, [selectedFilter]);

  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      setItems(data.data);

      // console.log(data.data, "rahull");
      setFilteredData(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getGalleryData();
  }, []);

  const carddata = [
    {
      desc: "Student Voting in School",
      dp: [
        "https://images.pexels.com/photos/16388234/pexels-photo-16388234/free-photo-of-moscow-street-art.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17591298/pexels-photo-17591298/free-photo-of-wood-city-street-building.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        "https://images.pexels.com/photos/17215283/pexels-photo-17215283/free-photo-of-bird-water-animal-lake.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      ],

      role1: "Design",
      onClick: handleOpen,
    },
  ];

  React.useEffect(() => {
    if (selectedCategory === "ALL") {
      setFilteredGalleryData(items);
    } else {
      const filteredData = items.filter(
        (item) => item.title === selectedCategory
      );
      setFilteredGalleryData(filteredData);
    }
  }, [selectedCategory, items]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    // console.log(category, "kkkkk");
  };

  let handleData = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <NavTopnav />
      <LibraryTitleContainer variant="h4" align="center">
        <LibraryTitle>GALLERY</LibraryTitle>
        <Typography sx={{ color: "white" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <Typography
              variant="span"
              sx={{
                color: "white",
                textTransform: "uppercase",
                "&:hover": {
                  color: "#F86F03",
                },
              }}
            >
              HOME
            </Typography>
          </Link>
          &nbsp; / Gallery
        </Typography>
      </LibraryTitleContainer>

      <TextBox>
        <Heading>DISCOVER NEW</Heading>
        <TypographyMain>Our Gallery</TypographyMain>
      </TextBox>
      <Box>
        <ul className={styles.list}>
          <li
            onClick={() => handleData("All")}
            style={{ borderLeft: "3px solid #ff4500", paddingLeft: "0.5rem" }}
          >
            ALL
          </li>
          {/* {[...galleryData.map((d) => d.title)].map((data, i) => {
            return (
              <React.Fragment key={i}>
                <li key={i} onClick={() => handleCategoryClick(data.title)}>
                  {data.title}
                </li>
              </React.Fragment>
            );
          })} */}
        </ul>
      </Box>

      <GridBox>
        {filteredGalleryData.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <GallerySubHome data={item} setModalOpen={setModalOpen} />
            </React.Fragment>
          );
        })}
      </GridBox>

      <Footer />
      {/* ======== View Gallery img ======= */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen.open}
        onClose={() =>
          setModalOpen({ open: false, imageData: [], viewSingleImg: {} })
        }
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ModalBox>
          <IconButton
            aria-label="close"
            onClick={() =>
              setModalOpen({ open: false, imageData: [], viewSingleImg: {} })
            }
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 9,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Carousel>
            {cutailImages?.map((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image?.link}
                alt="loading..."
                height="560"
                sx={{
                  borderRadius: "5px",
                  objectFit: "contain",
                  px: "15px",
                }}
              />
            ))}
          </Carousel>
        </ModalBox>
      </Modal>
    </>
  );
}
