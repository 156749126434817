import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
  Select,
  Divider,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
// icons
import { Add, Close, Search, Visibility } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
// custome components
import { del, get, post, put } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import appendStringToUrls from "../../../utils/appendString";
import SettingContext from "../../../context/SettingsContext";
import ShowComponent from "../../ShowComponent";
import css from "../../../styles/fees/ClassMap.module.css";
import moment from "moment";

function removeElementFromArray(array, elementToRemove) {
  const index = array.indexOf(elementToRemove);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

const MuiTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const MuiText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1b3779",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const Label = styled("label")(() => ({
  fontWeight: 600,
  fontSize: "15px",
  color: "#424242",
}));

const style = {
  position: "relative",
  width: "100%",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};

// const LABEL = {
//   class: "Class - (Academic department)",
//   route: "Route - (Transport department)",
//   pickType: "Pick-Type - (Transport department)",
//   stop: "Stop - (Transport department)",
//   room: "Room - (Hostel department)",
//   roomType: "Room Type - (Hostel department)",
//   hostel: "Hostel - (Hostel department)",
//   addedAfter: "Added After - (Student admission date)",
//   addedBefore: "Added Before - (Student admission date)",
//   academicYear: "Academic Year - (Student academic year)",
// };

const LABEL = {
  class: "Class",
  // classOld: "Class - (Old)",
  // classNew: "Class - (New)",
  transport: "Transport - (Transport department)",
  hostel: "Hostel - (Hostel department)",
};

const showInfo = (data) => {
  let result = [];

  for (let dep of data.dependencies) {
    if (["class"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class`;
      result.push(newItem);
    } else if (["classOld"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-Old`;
      result.push(newItem);
    } else if (["classNew"].includes(dep)) {
      let newItem = `[${data.class?.className}]-Class-New`;
      result.push(newItem);
    } else if (dep === "hostel") {
      let newItem = `[${data.hostel?.name}]-Hostel`;
      result.push(newItem);
    } else if (dep == "transport") {
      let newItem = `[${data.route.transportRouteTitle}]--[${data.stop.stopName}]--[${data.pickType}]`;
      result.push(newItem);
    } else if (dep == "pickType") {
      let newItem = `[${data.pickType}]-Pick_Type`;
      result.push(newItem);
    }
  }

  return result.join(" | ");
};

function FeeMap({ selectedClassMap }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [selectReceipt, setSelectReceipt] = useState(selectedClassMap || "");
  const [installmentType, setInstallMentType] = useState([
    {
      id: 1,
      label: "Monthly",
    },
    {
      id: 2,
      label: "Quaterly",
    },
    {
      id: 3,
      label: "Half-Yearly",
    },
    {
      id: 4,
      label: "Yearly",
    },
    {
      id: 4,
      label: "Others",
    },
  ]);

  const [addForm, setAddForm] = useState({});
  const [rooms, setRooms] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [hostels, setHostels] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [feeMaps, setFeeMaps] = useState([]);
  const [filteredFeeMaps, setFiltereredFeeMaps] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [editClassMap, setEditClassMap] = useState("");
  const [installments, setInstallments] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [showFeeMap, setShowFeeMap] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [allDependencies, setAllDependencies] = useState([]);
  const [stops, setStops] = useState([]);
  const [addedAfter, setAddedAfter] = useState(null);
  const [addedBefore, setAddedBefore] = useState(null);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // =======================

  useEffect(() => {
    const init = async () => {
      try {
        const { data: allReceipts, status } = await get(
          `${urls.receiptTitle.list}`,
          {
            headers: { roleFunction: "feeMap" },
          }
        );
        if (status > 199 && status < 299) {
          setReceipts(allReceipts.data);
          if (allReceipts.data.length > 0) {
            setSelectReceipt(
              selectReceipt ? selectReceipt : allReceipts.data[0]._id
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`,
          {
            headers: { roleFunction: "receiptTitle" },
          }
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
    getSchoolClasses();
  }, [selectedSetting._id]);

  useEffect(() => {
    if (selectReceipt) {
      const fetchClassMaps = async () => {
        try {
          await getFeeMaps();
        } catch (error) {}
      };
      fetchClassMaps();
    }
  }, [selectReceipt, selectedSetting._id]);

  const getFeeMaps = async () => {
    try {
      const { data: classMapList, status } = await get(
        `${urls.feeMap.list}/${selectReceipt}`,
        {
          headers: { roleFunction: "feeMap" },
        }
      );
      if (status > 199 && status < 299) {
        setFeeMaps(classMapList.data);
        setFiltereredFeeMaps(classMapList.data);
      }
    } catch (error) {
      return error;
    }
  };

  const getAllStops = async () => {
    try {
      const { data } = await get(urls.stopname.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setStops(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllStops();
  }, []);

  const resetForm = () => {
    setAddForm({});
    setEditClassMap("");
    setInstallments([]);
    setDependencies([]);
  };

  const handleCloseAddDialog = () => {
    setAddForm({});
    setEditClassMap("");
    setInstallments([]);
    setDependencies([]);
    setAddDialog(false);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    if (name == "fee") {
      setAddForm((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
    } else if (name == "others") {
      setAddForm((prev) => ({
        ...prev,
        [name]: Math.ceil(value),
      }));
    } else {
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      if (!editClassMap) {
        const { status } = await post(
          `${urls.feeMap.create}`,
          {
            receiptTitleId: selectReceipt,
            collectedFrom: "student",
            dependencies: dependencies,
            classId: addForm.schoolClass,
            routeId: addForm.route,
            pickType: addForm.pickType,
            hostelId: addForm.hostel,
            stopId: addForm.stop,
            fee: addForm.fee,
            installments: installments,
          },
          {
            headers: { roleFunction: "feeMap" },
          }
        );

        setSearch({});
        if (status > 199 && status < 299) {
          await getFeeMaps();
          resetForm();
        }
      } else {
        const { status } = await put(
          `${urls.feeMap.update}${editClassMap}`,
          "",
          {
            receiptTitleId: selectReceipt,
            collectedFrom: "student",
            dependencies: dependencies,
            classId: addForm.schoolClass,
            routeId: addForm.route,
            pickType: addForm.pickType,
            hostelId: addForm.hostel,
            stopId: addForm.stop,
            fee: addForm.fee,
            installments: installments,
          },
          {
            headers: { roleFunction: "feeMap" },
          }
        );
        setSearch({});
        if (status > 199 && status < 299) {
          await getFeeMaps();
          resetForm();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
    setLoader(false);
    handleCloseAddDialog();
  };

  const calculateInstallmentType = (number) => {
    if (number == 1) {
      return "Yearly";
    } else if (number == 2) {
      return "Half-Yearly";
    } else if (number === 4) {
      return "Quaterly";
    } else if (number == 12) {
      return "Monthly";
    } else {
      return "Others";
    }
  };

  const handleClassMapEdit = (id, data) => {
    setEditClassMap(id);
    console.log(data, "datta to edit");
    setAddForm({
      ...data,
      schoolClass: data.class?._id,
      route: data.route?._id,
      others: data.installments.length,
      hostel: data.hostel?._id,
      installmentType: calculateInstallmentType(data.installments.length),
    });
    setDependencies(data.dependencies);
    setInstallments(data?.installments);
    handleOpenAddDialog();
  };

  const handleAddInstallments = () => {
    if (!addForm.installmentType || !addForm.fee) return;
    let installments = [];

    if (addForm.installmentType === "Monthly") {
      const monthlyAmount = Math.floor(addForm.fee / 12);
      const missing = addForm.fee - monthlyAmount * 12;

      // console.log(missing, "missing");
      installments = Array.from({ length: 12 }).map((v, i) => ({
        id: i + 1,
        amount: monthlyAmount,
        missing: missing,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Quaterly") {
      const quarterlyAmount = Math.floor(addForm.fee / 4);
      const missing = addForm.fee - quarterlyAmount * 4;

      installments = Array.from({ length: 4 }).map((v, i) => ({
        id: i + 1,
        amount: quarterlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Half-Yearly") {
      const halfYearlyAmount = Math.floor(addForm.fee / 2);
      const missing = addForm.fee - halfYearlyAmount * 2;

      installments = Array.from({ length: 2 }).map((v, i) => ({
        id: i + 1,
        amount: halfYearlyAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else if (addForm.installmentType === "Yearly") {
      installments = Array.from({ length: 1 }).map((v, i) => ({
        id: i,
        amount: addForm.fee,
        dueDate: dayjs(),
      }));
    } else if (addForm.installmentType === "Others") {
      const othersAmount = Math.floor(addForm.fee / addForm.others);
      const missing = addForm.fee - othersAmount * addForm.others;

      installments = Array.from({ length: addForm.others }).map((v, i) => ({
        id: i + 1,
        amount: othersAmount,
        dueDate: dayjs(),
      }));
      if (installments.length > 0) {
        installments[0].amount += missing;
      }
    } else {
      setInstallments([]);
      return;
    }
    setInstallments(installments);
  };

  useEffect(() => {
    handleAddInstallments();
  }, [addForm.installmentType, addForm.others, addForm.fee]);

  const handleInstallmentChange = (val, key, changeIndex) => {
    setInstallments((prev) =>
      prev.map((installment, index) => {
        if (index === changeIndex) {
          return key === "amount"
            ? { ...installment, [key]: parseInt(val) }
            : key === "dueDate"
            ? { ...installment, [key]: val }
            : installment;
        } else if (index > changeIndex) {
          let diffInMonths = index - changeIndex;
          if (addForm.installmentType === "Quaterly") {
            diffInMonths = diffInMonths * 3;
          } else if (addForm.installmentType === "Half-Yearly") {
            diffInMonths = diffInMonths * 6;
          } else if (addForm.installmentType === "Others") {
            diffInMonths = diffInMonths * 1;
          }
          const updatedDueDate = dayjs(val)
            .add(diffInMonths, "month")
            .format("YYYY-MM-DD");
          return { ...installment, dueDate: updatedDueDate };
        } else {
          return installment;
        }
      })
    );
  };

  const handleStatusChange = async (id) => {
    try {
      const { status } = await put(
        `${urls.feeMap.updateStatus}${id}`,
        "",
        {},
        { headers: { roleFunction: "feeMap" } }
      );
      if (status > 199 && status < 299) {
        await getFeeMaps();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (chipToDelete) => {
    setDependencies((chips) => chips.filter((chip) => chip !== chipToDelete));
    if (["class", "classOld", "classNew"].includes(chipToDelete)) {
      setAddForm((prev) => ({ ...prev, schoolClasses: "" }));
    }
    if (chipToDelete === "hostel") {
      setAddForm((prev) => ({ ...prev, hostel: "" }));
    }
    if (chipToDelete === "transport") {
      setAddForm((prev) => ({ ...prev, stop: "", route: "", pickType: "" }));
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setDependencies(typeof value === "string" ? value.split(",") : value);
  };

  // all the data of dependencies;
  const getHostels = async () => {
    try {
      const { data } = await get(`${urls.hostel.getList}`, {
        headers: { roleFunction: "feeMap" },
      });
      setHostels(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getVehicles = async () => {
    try {
      const { data } = await get(urls.transport.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setVehicles(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutes = async () => {
    try {
      const { data } = await get(urls.route.getList, {
        headers: { roleFunction: "feeMap" },
      });
      setRoutes(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getClasses = async () => {
    try {
      const { data } = await get(urls.class.getAllClass, {
        headers: { roleFunction: "feeMap" },
      });

      setClasses(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (filteredFeeMaps.length) {
      let deps = [];
      for (let feeMap of filteredFeeMaps) {
        deps = [...deps, ...feeMap?.extendedDependencies];
      }

      let uniqueDeps = new Set(deps);
      setAllDependencies([...uniqueDeps]);
    }
  }, [filteredFeeMaps]);

  useEffect(() => {
    getHostels();
    getVehicles();
    getRoutes();
    getClasses();
  }, [selectedSetting._id]);

  const handleClickToView = (item) => {
    setShowFeeMap(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handelSelectSearch = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  useEffect(() => {
    let filter = {};
    if (search.className) {
      filter["class"] = search.className;
    }
    if (search.hostel) {
      filter["hostel"] = search.hostel;
    }

    if (search.route) {
      filter["route"] = search.route;
    }

    if (search.stopname) {
      filter["stop"] = search.stopname;
    }

    const check = (f, filter) => {
      let ok = true;
      for (let k of Object.keys(filter)) {
        if (k == "addedBefore") {
          if (moment(f[k]).diff(filter[k], "d") > 0) {
            ok = false;
            break;
          }
        }
        if (k == "addedAfter") {
          if (moment(f[k]).diff(filter[k], "d") < 0) {
            ok = false;
            break;
          }
        }
        if (
          f[k] instanceof Object ? f[k]._id != filter[k] : f[k] !== filter[k]
        ) {
          ok = false;
          break;
        }
      }
      return ok;
    };

    setFiltereredFeeMaps(feeMaps.filter((f) => check(f, filter)));
  }, [search]);

  const handleDeleteFeeMap = async (id) => {
    try {
      const { status } = await del(
        `${urls.feeMap.delete}${id}`,
        "",
        {},
        {
          roleFunction: "feeMap",
        }
      );
      if (status > 199 && status < 299) {
        await getFeeMaps();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box className={css.funcContainer}>
        <FormControl
          sx={{ width: { xs: "100%", sm: "100%", md: "30%", lg: "20%" } }}
          fullWidth
          size="small"
        >
          <InputLabel>Select Receipt</InputLabel>
          <Select
            fullWidth
            value={selectReceipt || ""}
            onChange={(e) => setSelectReceipt(e.target.value)}
            label="Select Receipt"
          >
            {receipts.map((receipt) => (
              <MenuItem value={receipt._id} key={receipt._id || ""}>
                {receipt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleOpenAddDialog}
          size="small"
          variant="contained"
          startIcon={<Add />}
        >
          Add Fee map
        </Button>
      </Box>
      <Box
        sx={{
          background: "#bebebe36",
          // paddingTop: 2,
          // paddingBottom: 1,
          marginBottom: 2,
          padding: "15px 10px 5px 10px",
          borderRadius: "5px",
        }}
      >
        <Label id="demo-simple-select-label">
          <FilterListIcon fontSize="small" />
          Filter
        </Label>

        <Grid container spacing={1} mb={2}>
          {allDependencies.includes("academicYear") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Academic Years</InputLabel>
                <Select
                  name="academicYear"
                  value={search.academicYear || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select academic Years"
                >
                  {academicYears &&
                    academicYears.map((row, index) => (
                      <MenuItem key={row._id} value={row._id || ""}>
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {allDependencies.includes("class") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Class</InputLabel>
                <Select
                  name="className"
                  value={search.className || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Class"
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem key={row._id} value={row._id || ""}>
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {allDependencies.includes("route") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Route</InputLabel>
                <Select
                  name="route"
                  value={search.route || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Route"
                >
                  {routes.map((route) => (
                    <MenuItem value={route._id || ""} key={route._id}>
                      {route.transportRouteTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {allDependencies.includes("stop") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Stop Name</InputLabel>
                <Select
                  name="stopname"
                  value={search.stopname || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Stop Name"
                >
                  {stops.map((stopName) => (
                    <MenuItem key={stopName._id} value={stopName._id || ""}>
                      {stopName.stopName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {allDependencies.includes("hostel") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Hostel</InputLabel>
                <Select
                  name="hostel"
                  value={search.hostel || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Hostel"
                >
                  {hostels.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {allDependencies.includes("roomType") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Room Type</InputLabel>
                <Select
                  name="roomType"
                  value={search.roomType || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Room Type"
                >
                  {roomTypes.map((row, index) => (
                    <MenuItem key={row._id} value={row._id || ""}>
                      {row?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {allDependencies.includes("room") && (
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Select Room</InputLabel>
                <Select
                  name="room"
                  value={search.room || ""}
                  onChange={(e) => handelSelectSearch(e)}
                  label="Select Room"
                >
                  {rooms &&
                    rooms.map((row, index) => (
                      <MenuItem key={row._id} value={row._id || ""}>
                        {row?.hostel.name}+ {row?.totalSeats} Bed+
                        {row?.type?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {Object.keys(search).filter((v) => search[v]).length ? (
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              setSearch({});
              setFiltereredFeeMaps(feeMaps);
            }}
          >
            Clear Filter
          </Button>
        ) : null}
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Details
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Fee
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Installments
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeeMaps
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((feeMap, index) => (
                <TableRow
                  key={feeMap._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{showInfo(feeMap)}</TableCell>
                  <TableCell align="center">{feeMap.fee}</TableCell>

                  <TableCell align="center">
                    <Tooltip title="View">
                      <Button
                        color="primary"
                        endIcon={<Visibility fontSize="small" />}
                        onClick={() => handleClickToView(feeMap?.installments)}
                      >
                        {feeMap.installments?.length}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <div className={css.tableActions}>
                      <ShowComponent roleFunction="feeMap" action="update">
                        <Button
                          onClick={() => handleClassMapEdit(feeMap._id, feeMap)}
                          size="small"
                          variant="contained"
                        >
                          edit
                        </Button>
                      </ShowComponent>
                      <ShowComponent roleFunction="feeMap" action="update">
                        <Button
                          color={!feeMap.active ? "success" : "error"}
                          onClick={() => handleStatusChange(feeMap._id)}
                          size="small"
                          variant="contained"
                        >
                          {feeMap.active ? "Deactivate" : "Activate"}
                        </Button>
                      </ShowComponent>

                      <Button
                        color={"error"}
                        onClick={() => handleDeleteFeeMap(feeMap._id)}
                        size="small"
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!filteredFeeMaps.length && (
          <MuiTypography variant="h6">No data found</MuiTypography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredFeeMaps.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: 1,
        }}
      />

      {/* ======= View Fee Map =================== */}
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            textAlign: "center",
          }}
        >
          Installment Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        {/* ======== Desktop view table ============ */}
        <DialogContent dividers>
          <Table aria-label="customized table" className="profile-table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                  #SL
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 300 }}
                >
                  Amount
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 200 }}
                >
                  Due Date
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showFeeMap.map((showData, i) => (
                <>
                  <TableRow>
                    <StyledTableCell align="center">{i + 1}</StyledTableCell>
                    <StyledTableCell align="center">
                      ₹{" "}
                      {showData.amount && showData.amount
                        ? showData.amount
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(
                        showData.dueDate && showData.dueDate
                          ? showData.dueDate
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>

      {/* ======= Add/Update Fee Map ============= */}
      <Dialog
        fullScreen={fullScreen}
        open={addDialog}
        onClose={handleCloseAddDialog}
      >
        <form onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MuiText>{editClassMap ? "Update" : "Add"} Fee Map</MuiText>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body">
                  Choose the dependencies that will serve as the basis for the
                  fee calculation.
                </Typography>
                <FormControl size="small" sx={{ mt: 2, width: 400 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Choose the dependencies
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    size="small"
                    multiple={
                      dependencies.includes("class")
                        ? false
                        : dependencies.includes("classOld")
                        ? false
                        : true
                    }
                    label="Select the dependencies"
                    value={dependencies}
                    onChange={handleChange}
                  >
                    {Object.keys(LABEL).map((name) => (
                      <MenuItem key={name} value={name}>
                        {LABEL[name]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {dependencies && (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {dependencies.map((value, i) => (
                      <Chip
                        sx={{ mt: 2 }}
                        key={value}
                        label={LABEL[value]}
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Box>
                )}
              </Grid>

              {(dependencies.includes("class") ||
                dependencies.includes("classNew") ||
                dependencies.includes("classOld")) && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Class</InputLabel>
                    <Select
                      size="small"
                      name="schoolClass"
                      required
                      value={addForm.schoolClass || ""}
                      onChange={handleAddForm}
                      label="Select Class"
                    >
                      {schoolClasses.map((schoolClass) => (
                        <MenuItem key={schoolClass._id} value={schoolClass._id}>
                          {schoolClass.className}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {dependencies.includes("transport") && (
                <>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Select Route</InputLabel>
                      <Select
                        size="small"
                        name="route"
                        required
                        value={addForm.route || ""}
                        onChange={handleAddForm}
                        label="Select Route"
                      >
                        {routes.map((route) => (
                          <MenuItem key={route._id} value={route._id}>
                            {route.vehicleNumber.vehicleNumber}{" "}
                            {route.transportRouteTitle}(
                            {`${route.routeStart} to ${route.routeEnd}`})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Select Stop</InputLabel>
                      <Select
                        size="small"
                        name="stop"
                        required
                        value={addForm.stop || ""}
                        onChange={handleAddForm}
                        label="Select stop"
                      >
                        {stops
                          ?.filter((s) =>
                            addForm.route ? s.route._id == addForm.route : s._id
                          )
                          .map((stop) => (
                            <MenuItem key={stop._id} value={stop._id}>
                              {stop.stopName}-{stop.route.transportRouteTitle}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Pick Type</InputLabel>
                      <Select
                        size="small"
                        name="pickType"
                        // required
                        value={addForm.pickType || ""}
                        onChange={handleAddForm}
                        label="Pick Type"
                      >
                        {["Drop", "Pick", "Both"].map((picktype) => (
                          <MenuItem key={picktype} value={picktype}>
                            {picktype}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {dependencies.includes("hostel") && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Hostel</InputLabel>
                    <Select
                      size="small"
                      name="hostel"
                      required
                      value={addForm.hostel || ""}
                      onChange={handleAddForm}
                      label="Select Hostel"
                    >
                      {hostels.map((hostel) => (
                        <MenuItem key={hostel._id} value={hostel._id}>
                          {hostel.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {/* {dependencies.includes("roomType") && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Room Type</InputLabel>
                    <Select
                      size="small"
                      name="roomType"
                      required
                      value={addForm.roomType || ""}
                      onChange={handleAddForm}
                      label="Select Room Type"
                    >
                      {roomTypes.map((roomType) => (
                        <MenuItem key={roomType._id} value={roomType._id}>
                          {roomType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {dependencies.includes("room") && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Room</InputLabel>
                    <Select
                      size="small"
                      name="room"
                      required
                      value={addForm.room || ""}
                      onChange={handleAddForm}
                      label="Select Room"
                    >
                      {rooms
                        .filter((r) => {
                          if (addForm.hostel && addForm.roomType) {
                            return (
                              r.hostel._id == addForm.hostel &&
                              r.type._id == addForm.roomType
                            );
                          } else if (addForm.hostel && !addForm.roomType) {
                            return r.hostel._id == addForm.hostel;
                          } else if (!addForm.hostel && addForm.roomType) {
                            return r.type._id == addForm.roomType;
                          } else {
                            return r._id;
                          }
                        })
                        .map((room) => (
                          <MenuItem key={room._id} value={room._id}>
                            {room.hostel.name} - {room.type.name} -{" "}
                            {`${room.totalSeats}-Beds`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {dependencies.includes("addedAfter") && (
                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Added After"
                      inputFormat="DD-MM-YYYY"
                      value={addedAfter}
                      onChange={(newValue) => setAddedAfter(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              {dependencies.includes("addedBefore") && (
                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Added Before"
                      inputFormat="DD-MM-YYYY"
                      value={addedBefore}
                      onChange={(newValue) => setAddedBefore(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              )} */}
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Fee"
                  type="number"
                  required
                  name="fee"
                  value={addForm.fee || ""}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Installment Type</InputLabel>
                  <Select
                    size="small"
                    name="installmentType"
                    value={addForm.installmentType || ""}
                    onChange={handleAddForm}
                    label="Installment Type"
                  >
                    {installmentType.map((installmentType) => (
                      <MenuItem
                        key={installmentType.id}
                        value={installmentType.label}
                      >
                        {installmentType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                {addForm.installmentType == "Others" && (
                  <TextField
                    // sx={{ marginTop: 1 }}
                    fullWidth
                    label="Number of installments"
                    size="small"
                    name="others"
                    type="number"
                    value={addForm.others || ""}
                    onChange={handleAddForm}
                  />
                )}
              </Grid>

              {installments.map((installment, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    gap={2}
                  >
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label={`installment ${index + 1}`}
                        value={installment.amount}
                        size="small"
                        enabled={editClassMap}
                        onChange={(e) =>
                          handleInstallmentChange(
                            e.target.value,
                            "amount",
                            index
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD-MM-YYYY"
                          enabled={editClassMap}
                          label="Due Date"
                          form
                          value={installment.dueDate}
                          onChange={(newValue) =>
                            handleInstallmentChange(newValue, "dueDate", index)
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              enabled={editClassMap}
                              {...params}
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-end",
                  lg: "flex-end",
                }}
                gap={2}
              >
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleCloseAddDialog();
                  }}
                >
                  Close
                </Button>

                <LoadingButton
                  loading={loader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },
                    color: "#fff",
                  }}
                >
                  {editClassMap ? "update" : "add"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
      {/* =========================== */}
    </>
  );
}

export default FeeMap;
