import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { del, get, post, put } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import SectionsSubHeader from "../../components/SectionSubHeader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Wrapper from "../Wrapper";
import Dialog from "@mui/material/Dialog";
import ShowComponent from "../ShowComponent";
const style = {
  position: "relative",
  width: "100%",
  maxWidth: 380,
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    maxWidth: 380,
    label: { fontSize: 12 },
  },
  Button: {
    background: "rgb(27, 55, 121)",
    ":hover": { background: "rgb(27, 55, 121)" },
  },
};

const HostelMember = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [academicYears, setAcademicYears] = useState([]);
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(null);
  const [sections, setSections] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [classes, setClasses] = useState([]);
  const [hostelName, setHostelName] = React.useState([]);
  const [roomName, setRoomName] = React.useState([]);
  const [feePlan, setFeePlan] = React.useState([]);
  const [hostelfee, setHostelfee] = React.useState([]);
  const [roomNo, setRoomNo] = React.useState([]);
  const [students, setStudents] = useState([]);
  const [nonStudents, setNonStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [editForm, setEditForm] = useState({});
  const [studentList, setStudentList] = React.useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [masterFeeOverview, setMasterFeeOverview] = useState([]);
  const [filterFeeOverview, setFilterFeeOverview] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const [loader, setLoader] = useState(false);
  const [nonLoader, setNonLoader] = useState(false);

  const [differentFeeOverView, setDifferentFeeOverView] = useState({
    modes: {},
    total: 0,
  });
  const [studentToAdd, setStudentToAdd] = useState("");
  const [mode, setMode] = useState("edit");
  const [beds, setBeds] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(300));
  const [finder, setFinder] = useState(false);
  const [member, setMember] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // pagination================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ====================================

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
    room: "asc",
  });
  const sortByTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [
        ...students.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...students.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    if (sort.rollNo === "asc") {
      const sortedList = [
        ...students.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      const sortedList = [
        ...students.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  const sortByRoom = () => {
    if (sort.room === "asc") {
      const sortedList = [
        ...students.sort((a, b) =>
          b?.hostelInfo?.hostel?.name?.localeCompare(a.hostelInfo?.hostel?.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, room: "des" });
    } else {
      const sortedList = [
        ...students.sort((a, b) =>
          a?.hostelInfo?.hostel?.name?.localeCompare(b.hostelInfo?.hostel?.name)
        ),
      ];
      setStudents(sortedList);
      setSort({ ...sort, room: "asc" });
    }
  };
  const sortByNonTitle = () => {
    if (sort.name === "asc") {
      const sortedList = [
        ...nonStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByNonRollNo = () => {
    if (sort.rollNo === "asc") {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      const sortedList = [
        ...nonStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setNonStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll, {
          headers: { roleFunction: "hostelmember" },
        });
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data.length ? res.data.data[0]._id : "",
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting._id]);

  const handleAddHostelButtonClick = async (id) => {
    setMode("create");
    setStudentToAdd(id);
    const selectedStudent = students.find((student) => student._id === id);
    setSelectedStudentName(
      selectedStudent ? selectedStudent.basicInfo.name : null
    );

    // handleStudentSearch();
    setOpen(true);
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass, {
          headers: { roleFunction: "hostelmember" },
        });
        const resSections = await get(urls.schoolSection.getAllSection, {
          headers: { roleFunction: "hostelmember" },
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "hostelmember" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, [selectedSetting._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnEditChange = (e) => {
    const { name, value } = e.target;

    // if (name === "hostel") {
    //   let newRoomsList = roomNo.filter((r) => r.hostel === value);
    //   setRoomNo(newRoomsList); // Set the filtered rooms based on the selected hostel
    // }

    setEditForm({
      ...editForm,
      [name]: value,
    });
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        headers: { roleFunction: "hostelmember" },
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleStudentSearch = async (e) => {
  //   setNonLoader(true);
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   try {
  //     const searchOptions = {
  //       academicYear: formData.academicYear,
  //       "academicInfo.class": formData.class,

  //       status: "active",
  //     };

  //     if (formData.section !== "all") {
  //       searchOptions["academicInfo.section"] = formData.section;
  //     }

  //     const searchStudents = await get(urls.students.getAllStudent, {
  //       headers: { roleFunction: "hostelmember" },
  //       params: {
  //         search: searchOptions,
  //         limit: rowsPerPage,
  //         page: page + 1,
  //       },
  //     });

  //     console.log(
  //       searchStudents.data.data,
  //       "================================================"
  //     );

  //     const nonMemberList = searchStudents.data.data.filter(
  //       (student) => student?.otherInfo?.hostelMember === "no"
  //     );

  //     const membersList = searchStudents.data.data.filter(
  //       (student) => student?.otherInfo?.hostelMember === "yes"
  //     );

  //     setNonStudents(nonMemberList);
  //     setStudents(membersList);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setNonLoader(false);
  // };

  const handleStudentSearch = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setLoader(true);
      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "hostelmember" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
            },
          },
        });
        const membersList = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.hostelMember === "yes"
        );
        const nonMemberList = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.hostelMember === "no"
        );

        setStudents(membersList);
        setNonStudents(nonMemberList);
      } else {
        const searchStudents = await get(urls.students.getAllStudent, {
          headers: { roleFunction: "hostelmember" },
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        const membersList = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.hostelMember === "yes"
        );
        const nonMemberList = searchStudents.data.data.filter(
          (student) => student?.otherInfo?.hostelMember === "no"
        );

        setStudents(membersList);
        setNonStudents(nonMemberList);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.academicYear && formData.className && formData.section) {
      handleStudentSearch();
    }
  }, [
    formData.academicYear,
    formData.className,
    formData.section,
    selectedSetting,
  ]);

  const [selectedHostelName, setSelectedHostelName] = useState();

  useEffect(() => {
    const getHostelNames = async () => {
      try {
        const { data, status } = await get(`${urls.hostel.getList}`, {
          headers: { roleFunction: "hostelmember" },
        });
        if (status > 199 && status < 299) {
          setHostelName(data.data);
          setSelectedHostelName(data.data[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getHostelNames();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getRoomNo = async () => {
      try {
        const { data, status } = await get(`${urls.room.getList}`, {
          headers: { roleFunction: "hostelmember" },
        });
        if (status > 199 && status < 299) {
          const sortedRoomNo = data.data.sort((a, b) => a.number - b.number);
          setRoomNo(sortedRoomNo);
          console.log(sortedRoomNo, "sortedRoomNo");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomNo();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getRoomName = async () => {
      try {
        const { data, status } = await get(`${urls.room.getList}`, {
          headers: { roleFunction: "hostelmember" },
        });
        if (status > 199 && status < 299) {
          setRoomName(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomName();
  }, [selectedSetting]);

  useEffect(() => {
    if (editForm.room) {
      let interestedRoom = roomName.filter((r) => r._id == editForm.room)[0];
      setBeds(interestedRoom.beds);
    }
  }, [editForm, selectedSetting._id]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClickOpen = (id) => {
    setMode("edit");
    let studentToEdit = students.filter((s) => s._id == id)[0];
    setEditForm({
      hostel: studentToEdit.hostelInfo
        ? studentToEdit.hostelInfo.hostel?._id
        : "",
    });
    setStudentToAdd(studentToEdit._id);

    setOpen(true);
  };

  const handleClose = () => {
    // setEditHostel(null);
    setEditForm({});
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await put(
        `${urls.hostelMember.editMember}/`,
        studentToAdd,
        {
          hostel: editForm.hostel,
        },
        {
          headers: { roleFunction: "hostelmember" },
        }
      );

      if (res.data.success) {
        handleClose();

        const filteredEvent = nonStudents.filter(
          (item) => item._id !== studentToAdd
        );
        setNonStudents(filteredEvent);

        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await put(
        `${urls.hostelMember.deleteMember}`,
        id,
        {},
        { headers: { roleFunction: "hostelmember" } }
      );
      if (res.data.success) {
        const filteredEvent = students.filter((item) => item._id !== id);
        setStudents(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      <SectionsSubHeader title="Hostel Member" />

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "15px 0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Member List" {...a11yProps(0)} />
          <Tab label="Non Member List" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Card style={{ padding: 10 }}>
          {/* <Box sx={{ flexGrow: 1 }}> */}
          <form onSubmit={handleStudentSearch}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    size="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {formData.className && <MenuItem value="all">All</MenuItem>}
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-start",
                  lg: "flex-start",
                }}
                alignSelf="center"
              >
                <LoadingButton
                  loading={nonLoader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    color: "#fff",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  SEARCH
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
          {/* </Box> */}
        </Card>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByTitle} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByRollNo} className="class-table-header">
                    Admission No
                    <span style={{ cursor: "pointer" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  <span onClick={sortByRoom} className="class-table-header">
                    Hostel
                    <span style={{ cursor: "pointer" }}>
                      {sort.room === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((name, index) => (
                  <TableRow key={name._id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{name.basicInfo.name}</TableCell>

                    <TableCell align="center">
                      {name.academicInfo.admissionNo}
                    </TableCell>

                    <TableCell align="center">
                      {name.hostelInfo?.hostel?.name}
                    </TableCell>
                    <TableCell align="center">
                      {name?.otherInfo?.hostelMember === "yes" ? (
                        <>
                          <ShowComponent
                            roleFunction="hostelmember"
                            action="update"
                          >
                            <Tooltip title="Update">
                              <IconButton
                                size="small"
                                style={{ color: "#1b3779" }}
                                onClick={() => handleClickOpen(name._id)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <ShowComponent
                            roleFunction="hostelmember"
                            action="delete"
                          >
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => setDeleteModal(name._id)}
                              >
                                <Delete fontSize="small" color="error" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDeleteone}
                            id={name._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </>
                      ) : (
                        <ShowComponent
                          roleFunction="hostelmember"
                          action="update"
                        >
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleAddHostelButtonClick(name._id)}
                            startIcon={<AddIcon fontSize="small" />}
                          >
                            Hostel
                          </Button>
                        </ShowComponent>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!students.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={students.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start,",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Card style={{ padding: 10 }}>
          {/* <Box sx={{ flexGrow: 1 }}> */}
          <form onSubmit={handleStudentSearch}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    size="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required size="small">
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {formData.className && <MenuItem value="all">All</MenuItem>}
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                    lg: "flex-start",
                  },
                }}
              >
                <LoadingButton
                  loading={nonLoader}
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    color: "#fff",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  SEARCH
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
          {/* </Box> */}
        </Card>
        <Box
          overflow={{ xs: "scroll", md: "visible" }}
          style={{ margin: "25px 0" }}
        >
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByNonTitle}
                      className="class-table-header"
                    >
                      Name
                      <span style={{ cursor: "pointer" }}>
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <span
                      onClick={sortByNonRollNo}
                      className="class-table-header"
                    >
                      Admission No
                      <span style={{ cursor: "pointer" }}>
                        {sort.rollNo === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonStudents
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((name, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {name.basicInfo.name}
                      </TableCell>

                      <TableCell align="center">
                        {name.academicInfo.admissionNo}
                      </TableCell>

                      <TableCell align="center">
                        <ShowComponent
                          roleFunction="hostelmember"
                          action="update"
                        >
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleAddHostelButtonClick(name._id)}
                            startIcon={<AddIcon fontSize="small" />}
                          >
                            Hostel
                          </Button>
                        </ShowComponent>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!nonStudents.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={nonStudents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start,",
              alignItems: "center",
              my: 1,
            }}
          />
        </Box>
      </TabPanel>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={(e) => handleAddSubmit(e)}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign="center"
                  fontSize="20px"
                  fontWeight="bold"
                >
                  {mode === "edit" ? "Update Hostel" : "Add Hostel"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontSize="15px"
                  fontWeight="bold"
                  textAlign="start"
                >
                  {selectedStudentName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  fullWidth
                  required
                  size="small"
                  style={styles.textfield}
                >
                  <InputLabel>Select Hostel</InputLabel>
                  <Select
                    size="small"
                    label="Select Hostel"
                    id="demo-simple-select-filled"
                    variant="outlined"
                    value={editForm.hostel || ""}
                    name="hostel"
                    fullWidth
                    onChange={(e) => handleOnEditChange(e)}
                  >
                    {hostelName.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  fullWidth
                  required
                  size="small"
                  style={styles.textfield}
                >
                  <InputLabel>Select Room No</InputLabel>
                  <Select
                    size="small"
                    label="Select Room No"
                    id="demo-simple-select-filled"
                    name="room"
                    fullWidth
                    value={editForm.room || ""}
                    onChange={(e) => handleOnEditChange(e)}
                  >
                    {roomNo.map((num) => (
                      <MenuItem key={num._id} value={num._id}>
                        {num.number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  fullWidth
                  required
                  size="small"
                  style={styles.textfield}
                >
                  <InputLabel>Select Bed</InputLabel>
                  <Select
                    label="Select Bed"
                    id="demo-simple-select-filled"
                    value={editForm.bed || ""}
                    onChange={(e) => handleOnEditChange(e)}
                    name="bed"
                    fullWidth
                    size="small"
                  >
                    {beds.map((b) => (
                      <MenuItem key={b._id} value={b._id}>
                        {b.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                gap={1}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: {
                    md: "end",
                    sm: "center",
                    xs: "center",
                  },
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="error"
                  size="small"
                  type="button"
                  sx={{ color: "#ffff" }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loader}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                >
                  {mode === "edit" ? "update" : "Add"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </Wrapper>
  );
};

export default HostelMember;
